import React from "react";
import { useTranslate } from "react-admin";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Customer, ShowComponentProps } from "../types";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { width: 600, margin: "auto" },
  paper: {
    margin: "0 auto",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  featuresGrid: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& *": {
      letterSpacing: "0.01071em",
      pointerEvents: "none",
      marginLeft: 0,
    },
    padding: "6px 8px",
  },
}));

const customerDetais: string[] = [
  "id",
  "acumatica_id",
  "name",
  "address_line1",
  "address_line2",
  "address_line3",
  "city",
  "state",
  "country",
  "postal_code",
  "fedex_account_id",
  "fedex_customer_reference",
  "department_note",
  "po_number",
  "invoice_number",
  "minimum_insurance",
  "ups_account_id",
  "reference1",
  "reference2",
  "reference3",
  "reference4",
  "reference5",
  "use_weight",
];

const CustomerShow: React.FC<ShowComponentProps & { record?: Customer }> = (
  props
) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { record } = props;

  // if (record === undefined || data === undefined || loading) {
  //   return null;
  // }

  React.useEffect(() => {
    console.log(props);
  });

  if (record === undefined) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Paper className={classes.paper}>
          {customerDetais.map((row) => (
            <Grid
              className={classes.featuresGrid}
              container
              wrap="wrap"
              key={`customer-detail-row-${row}`}
            >
              <Grid item xs={6} zeroMinWidth>
                <Typography variant="subtitle2" color="textPrimary" noWrap>
                  {translate(`resources.customer.fields.${row}`)}
                </Typography>
              </Grid>
              <Grid item xs={6} zeroMinWidth>
                <Typography
                  variant="body2"
                  align="right"
                  color="textSecondary"
                  noWrap
                >
                  {record[row]}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Paper>
      </CardContent>
    </Card>
  );
};

export default CustomerShow;
