import React, { FC } from "react";
import { fetchUtils } from "ra-core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { Button, useNotify, useRefresh, useUnselectAll } from "react-admin";
import { BulkActionProps } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

declare type Identifier = string | number;

const noSelection: Identifier[] = [];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: `2px ${theme.spacing(1)}px`,
      position: "relative",
    },
    buttonProgress: {
      // color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const httpClient = fetchUtils.fetchJson;

const PrintShipmentsButton: FC<BulkActionProps> = ({
  selectedIds = noSelection,
  filterValues,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("order");

  const classes = useStyles();

  const [shipmentIds, setShipmentIds] = React.useState<Identifier[]>([]);
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef<number>();

  React.useEffect(() => {
    setShipmentIds(selectedIds);
    return () => {
      setShipmentIds([]);
      Promise.resolve(httpClient);
      clearTimeout(timer.current);
    };
  }, [selectedIds]);

  const downloadPDF = (pdfData: string) => {
    const linkSource: string = pdfData;
    const downloadLink: HTMLAnchorElement = document.createElement("a");
    // @ts-ignore
    const { ship_via, shipment_type, shipment_status } = filterValues;
    const now = new Date();
    const currentMonth = ("0" + (now.getMonth() + 1)).slice(-2);
    const fileName: string = `${ship_via || ""}-${shipment_type || ""}-${
      shipment_status || ""
    }_${currentMonth}-${now.getDate()}-${now.getFullYear()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const CheckAndLoop = (uuid: string) => {
    httpClient(
      `${process.env.REACT_APP_HEROKU_API_URL}/shipment/labels/${uuid}`
    )
      .then((response) => {
        switch (response.json.status) {
          case "success":
            notify(
              "resources.order.actions.print.success",
              "success",
              {},
              false,
              2000
            );
            downloadPDF(response.json.data);
            clearTimeout(timer.current);
            unselectAll();
            refresh();
            break;
          case "processing":
            notify(
              "resources.order.actions.print.process",
              "warning",
              {},
              false,
              2000
            );
            timer.current = window.setTimeout(() => {
              CheckAndLoop(uuid);
            }, 10000);
            break;
          case "error":
            notify(
              "resources.order.actions.print.error",
              "error",
              { reason: response.json.message },
              false,
              4000
            );
            break;
          default:
            notify(
              "resources.order.actions.print.unknown",
              "warning",
              {},
              false,
              4000
            );
            break;
        }
      })
      .catch((reason) => {
        notify(
          "resources.order.actions.print.error",
          "error",
          { reason: reason },
          false,
          4000
        );
        setLoading(false);
      })
      .finally(() => {});
  };

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let options = {
        headers: new Headers({}),
      };
      const email = localStorage.getItem("email");
      options.headers.set("X-Email", email ? email : "");
      let url = `${
        process.env.REACT_APP_HEROKU_API_URL
      }/shipment/labels?ids=${shipmentIds.toString()}&preview=false`;
      if (filterValues && filterValues.shipment_type) {
        url = `${url}&shipment_type=${filterValues.shipment_type}`;
      }
      httpClient(url, options)
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.print.success",
                "success",
                {},
                false,
                2000
              );
              CheckAndLoop(response.json.data);
              break;
            case "error":
              notify(
                "resources.order.actions.print.error",
                "error",
                { reason: response.json.message },
                false,
                4000
              );
              break;
            default:
              notify(
                "resources.order.actions.print.unknown",
                "warning",
                {},
                false,
                4000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.print.error",
            "error",
            { reason: reason },
            false,
            4000
          );
          setLoading(false);
        })
        .finally(() => {});
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          label="Download"
          disabled={loading}
          onClick={handleButtonClick}
        >
          <CloudDownloadIcon />
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

export default PrintShipmentsButton;
