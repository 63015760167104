// import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
// import themeReducer from "../themeReducer";

export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#4f3cc9",
    },
    secondary: {
      light: "#545454",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  shape: {
    borderRadius: 3,
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: "14px",
      },
      input: {
        fontSize: "14px",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        color: "rgba(0, 0, 0, 0.87)",
        border: "0px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#4f3cc9",
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      root: {
        border: "0 none",
      },
      colorSecondary: {
        color: "#bbc5ca",
        backgroundColor: "#1e282d",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-expanded": {
          backgroundColor: "rgba(67, 149, 255, 0.1)",
          marginTop: "0",
          marginBottom: "0",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: "36px",
        "&.Mui-expanded": {
          minHeight: "36px",
        },
      },
      content: {
        margin: "8px 0",
        alignItems: "center",
        "&.Mui-expanded": {
          margin: "12px 0",
        },
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 8px",
      },
    },
    RaLayout: {
      content: {
        padding: 0,
      },
    },
    RaAppBar: {
      menuButton: {
        marginLeft: "0.15rem",
        marginRight: "0.5rem",
      },
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: "#37474f",
        overflowX: "hidden",
      },
    },
  },
};
