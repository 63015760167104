import React from "react";
import { fetchUtils } from "ra-core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  objectPdf: { width: "100%", minHeight: "800px" },
  downloadWrapper: {
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: "1 0 0",
  },
  downloadButton: {
    margin: `${theme.spacing(3)}px auto`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface PrintLabelDialogProps extends DialogProps {
  items?: string | null;
  pdfSource?: string;
  onClose?: (event: React.SyntheticEvent, reason?: string) => void;
}

const httpClient = fetchUtils.fetchJson;

const PrintLabelModal: React.FC<PrintLabelDialogProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [loading, setLoading] = React.useState<Boolean>(true);
  const [pdfData, setPdfData] = React.useState<string | null>();

  const handleDialog = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    let options = {
      headers: new Headers({}),
    };
    const email = localStorage.getItem("email");
    options.headers.set("X-Email", email ? email : "");
    props.pdfSource === undefined &&
      props.items?.length &&
      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/labels?ids=${props.items}&preview=true`,
        options
      )
        .then((response) => {
          response.json.data && setPdfData(response.json.data);
        })
        .finally(() => setLoading(false));

    return () => {
      setPdfData(null);
    };
  }, [props.items, props.pdfSource]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      open={props.open}
      onEntered={() => {}}
      onExited={handleDialog}
      keepMounted
      onBackdropClick={props.onClose}
      aria-labelledby="item-label-dialog-title"
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
        key={`dialog-title${props.items?.toString()}`}
      >
        {`${translate(
          `resources.order.fields.shipment_id`
        )} ${props.items?.toString()} [PDF]`}
      </DialogTitle>
      <DialogContent dividers={true} className={classes.noGutter}>
        <Paper square variant="elevation">
          {props.pdfSource !== undefined ? (
            <div className={classes.downloadWrapper}>
              <Button
                variant="contained"
                href={props.pdfSource}
                target="_blank"
                className={classes.downloadButton}
                startIcon={<CloudDownloadIcon />}
                color="primary"
              >
                Download
              </Button>
            </div>
          ) : loading ? (
            <LinearProgress color="secondary" />
          ) : !pdfData ? (
            <Alert severity="error">Label Source Error!</Alert>
          ) : (
            <object
              data={pdfData}
              type="application/pdf"
              className={classes.objectPdf}
              role="document"
              aria-live="assertive"
              aria-label={`pdf-label-${props.items?.toString()}`}
            ></object>
          )}
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          size="small"
          endIcon={<CloseIcon fontSize="small" />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintLabelModal;
