import React, { FC } from "react";
import { fetchUtils } from "ra-core";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import { Button, useNotify, useRefresh, useUnselectAll } from "react-admin";
import { BulkActionProps } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

declare type Identifier = string | number;

const noSelection: Identifier[] = [];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: `2px ${theme.spacing(1)}px`,
      position: "relative",
    },
    buttonProgress: {
      // color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const httpClient = fetchUtils.fetchJson;

const CorrectShipmentsButton: FC<BulkActionProps> = ({
  selectedIds = noSelection,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("order");

  const classes = useStyles();

  const [shipmentIds, setShipmentIds] = React.useState<Identifier[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setShipmentIds(selectedIds);
    return () => {
      setShipmentIds([]);
      Promise.resolve(httpClient);
    };
  }, [selectedIds]);

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let options = {
        headers: new Headers({}),
      };
      const email = localStorage.getItem("email");
      options.headers.set("X-Email", email ? email : "");
      httpClient(
        `${
          process.env.REACT_APP_HEROKU_API_URL
        }/shipment/update?ids=${shipmentIds.toString()}`,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.correct.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.correct.error",
                "error",
                { reason: response.json.message },
                false,
                4000
              );
              Array.isArray(response.json.data) &&
                response.json.data.map((error: any) => {
                  notify(
                    "resources.order.actions.correct.error",
                    "error",
                    { reason: error.error_source },
                    false,
                    4000
                  );
                  notify(
                    "resources.order.actions.correct.error",
                    "error",
                    { reason: error.error_message },
                    false,
                    4000
                  );
                  return error;
                });
              break;
            default:
              notify(
                "resources.order.actions.correct.unknown",
                "warning",
                {},
                false,
                4000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.correct.error",
            "error",
            { reason: reason },
            false,
            4000
          );
          setLoading(false);
        })
        .finally(() => {
          unselectAll();
          refresh();
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          label="Correct Shipments"
          disabled={loading}
          onClick={handleButtonClick}
        >
          <AutorenewIcon />
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

export default CorrectShipmentsButton;
