const config = {
  apiKey: "AIzaSyB7V4-hBTdVTv99Pgiq_H5cKH5EPIMrxVo",
  authDomain: "tayse-test.firebaseapp.com",
  databaseURL: "https:\\/\\/tayse-test.firebaseio.com",
  projectId: "tayse-test",
  storageBucket: "tayse-test.appspot.com",
  messagingSenderId: "76996848282",
  appId: "1:76996848282:web:996239d959d00ef341cd23",
};

const options = {};

export { config, options };
