import React, { FC, Fragment } from "react";
import classnames from "classnames";
import {
  ExportButton,
  Datagrid,
  List,
  Pagination,
  TextField,
  TopToolbar,
} from "react-admin";

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { BulkActionProps, ListComponentProps } from "../types";
import { PaginationProps } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import CustomerShow from "./CustomerShow";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flex: "1 0 auto",
    minHeight: "100%",
  },
  list: {
    flexGrow: 1,
    paddingRight: "0.5em",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 0,
    borderLeftStyle: "solid",
    borderBottomColor: grey[500],
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  headerCell: {
    padding: "8px",
    backgroundColor: grey[200],
  },
  rowCell: {
    padding: "8px",
    verticalAlign: "middle",
  },
}));

const raThemeProvider = {
  overrides: {
    RaListToolbar: {
      toolbar: {
        backgroundColor: "rgb(255, 255, 255)",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        color: "#2b1a1a",
        height: "64px",
        minHeight: "64px",
        backgroundColor: "rgb(133, 176, 198)",
      },
    },
  },
};

const CustomerList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const classes = useStyles();

  const CustomerBulkActionButtons: FC<BulkActionProps> = (props) => (
    <Fragment></Fragment>
  );

  const CustomerListActions: FC<any> = () => (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );

  const PostPagination = (props: PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  return (
    <ThemeProvider theme={raThemeProvider}>
      <div className={classes.root}>
        <List
          {...props}
          className={classnames(classes.list)}
          bulkActionButtons={<CustomerBulkActionButtons />}
          // filterDefaultValues={{ status: "name" }}
          sort={{ field: "id", order: "ASC" }}
          pagination={<PostPagination />}
          perPage={50}
          actions={<CustomerListActions />}
        >
          <Datagrid
            rowClick="expand"
            expand={
              <CustomerShow
                id={(props.match as any).params.id as string}
                {...props}
              />
            }
            classes={{
              headerRow: classes.headerRow,
              headerCell: classes.headerCell,
              rowCell: classes.rowCell,
            }}
            optimized
          >
            <TextField source="acumatica_id" />
            <TextField source="name" />
          </Datagrid>
        </List>
      </div>
    </ThemeProvider>
  );
};

export default CustomerList;
