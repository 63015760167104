import React, { FC, Fragment, useCallback } from "react";
import {
  Avatar,
  Divider,
  Tab,
  Tabs,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import PrintIcon from "@material-ui/icons/Print";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from "@material-ui/icons/Close";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {
  deepOrange,
  deepPurple,
  grey,
  red,
  green,
  orange,
  blueGrey,
} from "@material-ui/core/colors";
import { Identifier } from "ra-core";
import { DatagridProps, Order } from "../types";
import OrderListDesktop from "./OrderListDesktop";

const useStyles = makeStyles((theme: Theme) => ({
  tabRoot: {
    minHeight: "auto!important",
  },
  tabbed: {
    borderRight: `1px solid ${blueGrey[100]}`,
    minHeight: "auto!important",
    "&.fedexups": {
      background:
        "linear-gradient(90deg, rgba(41,0,124,0.1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(255,180,6,0.1) 100%);",
    },
    "&.Mui-selected.fedexups": {
      background:
        "linear-gradient(90deg, rgba(41,0,124,0.25) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 60%, rgba(255,180,6,0.25) 100%);",
    },
    "&.fedex": { backgroundColor: "rgba(41, 0, 124, 0.1)" },
    "&.ups": { backgroundColor: "rgba(255, 180, 6, 0.1)" },
    "&.Mui-selected.fedex": { backgroundColor: "rgba(41, 0, 124, 0.2)" },
    "&.Mui-selected.ups": { backgroundColor: "rgba(255, 180, 6, 0.2)" },
    "&:first-child": { borderLeft: `1px solid ${blueGrey[100]}` },
  },
  avatar: {
    borderRadius: "0",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    maxWidth: "100%",
    width: "64px",
    height: "32px",
    "& > img": {
      height: "32px",
      width: "100%",
      objectFit: "contain",
      opacity: ".65",
    },
    ".Mui-selected & > img": {
      opacity: "1",
    },
  },
}));

const raThemeProvider = {
  overrides: {
    // Tabs custom styles
    MuiTab: {
      root: {
        "@media (min-width:600px)": {
          minWidth: "100px",
        },
      },
      wrapper: {
        flexDirection: "row",
        "& > :first-child": {
          margin: "auto 8px !important",
        },
      },
    },
  },
};

interface TabbedDatagridProps extends DatagridProps<Order> {
  selectedRowId?: string;
  handleStatusTab: (viaTab: string, statusTab: string) => void;
}

const TabbedOrderDatagrid: FC<TabbedDatagridProps> = ({
  ids,
  filterValues,
  setFilters,
  displayedFilters,
  selectedRowId,
  handleStatusTab,
  ...rest
}) => {
  const classes = useStyles();

  const [selectedStatusTab, setSelectedStatusTab] = React.useState<string>(
    filterValues.shipment_status !== undefined
      ? filterValues.shipment_status
      : "Open"
  );
  const [selectedViaTab, setSelectedViaTab] = React.useState<any>(
    filterValues.ship_via !== undefined ? filterValues.ship_via : ""
  );

  const [openIds, setOrderedIds] = React.useState<Identifier[]>([]);
  const [errorIds, setErrorIds] = React.useState<Identifier[]>([]);
  const [printedIds, setPrintedIds] = React.useState<Identifier[]>([]);
  const [warningIds, setWarningIds] = React.useState<Identifier[]>([]);
  const [confirmedIds, setConfirmedIds] = React.useState<Identifier[]>([]);
  const [cancelledIds, setCancelledIds] = React.useState<Identifier[]>([]);
  const [deliveredIds, setDeliveredIds] = React.useState<Identifier[]>([]);

  React.useEffect(() => {
    if (ids && ids !== (selectedStatusTab as any)) {
      switch (selectedStatusTab) {
        case "Open":
        case "Pickup Requested":
          setOrderedIds(ids);
          break;
        case "Error":
          setErrorIds(ids);
          break;
        case "Printed":
          setPrintedIds(ids);
          break;
        case "Warning":
          setWarningIds(ids);
          break;
        case "Confirmed":
        case "In Transit":
          setConfirmedIds(ids);
          break;
        case "Cancelled":
          setCancelledIds(ids);
          break;
        case "Delivered":
          setDeliveredIds(ids);
          break;
      }
    }

    handleStatusTab(selectedViaTab, selectedStatusTab);
  }, [ids, selectedViaTab, selectedStatusTab, handleStatusTab]);

  const handleChangeVia = useCallback(
    (event: React.ChangeEvent<{}>, value?: any) => {
      setSelectedViaTab(value);
      handleStatusTab(selectedViaTab ?? "", selectedStatusTab);

      setFilters &&
        setFilters(
          {
            ...filterValues,
            ship_via: value,
          },
          displayedFilters
        );
    },
    [
      displayedFilters,
      filterValues,
      setFilters,
      handleStatusTab,
      selectedViaTab,
      selectedStatusTab,
    ]
  );

  const handleChangeStatus = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setSelectedStatusTab(value);
      handleStatusTab(selectedViaTab ?? "", selectedStatusTab);

      setFilters &&
        setFilters(
          { ...filterValues, shipment_status: value },
          displayedFilters
        );
    },
    [
      displayedFilters,
      filterValues,
      setFilters,
      handleStatusTab,
      selectedViaTab,
      selectedStatusTab,
    ]
  );

  return (
    <ThemeProvider theme={raThemeProvider}>
      <Fragment>
        <Tabs
          variant="fullWidth"
          centered
          value={selectedViaTab}
          indicatorColor="primary"
          onChange={handleChangeVia}
          aria-label="shipvia-tab-tabs-states"
          textColor="primary"
          className={classes.tabRoot}
        >
          <Tab
            wrapped
            className={`${classes.tabbed} fedexups`}
            icon={
              <React.Fragment>
                <Avatar src={`/svg/fedex.svg`} className={classes.avatar} />
                <RemoveIcon />
                <Avatar src={`/svg/ups.svg`} className={classes.avatar} />
              </React.Fragment>
            }
            key="shipvia-tab-fedex-ups"
            selected
            value={String("")}
          />
          <Tab
            wrapped
            className={`${classes.tabbed} fedex`}
            icon={<Avatar src={`/svg/fedex.svg`} className={classes.avatar} />}
            key="shipvia-tab-fedex"
            value="FedEx"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ups`}
            icon={<Avatar src={`/svg/ups.svg`} className={classes.avatar} />}
            key="shipvia-tab-ups"
            value="UPS"
          />
        </Tabs>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.shipment_status}
          indicatorColor="primary"
          onChange={handleChangeStatus}
          aria-label="shipment-tab-tabs-states"
          textColor="primary"
          className={classes.tabRoot}
        >
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<ScheduleOutlinedIcon style={{ color: deepOrange[500] }} />}
            key="shipment-tab-Open"
            label="Open"
            value="Open"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<ErrorIcon style={{ color: red[700] }} />}
            key="shipment-tab-Error"
            label="Error"
            value="Error"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<PrintIcon style={{ color: grey[700] }} />}
            key="shipment-tab-Printed"
            label="Printed"
            value="Printed"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<WarningIcon style={{ color: orange[700] }} />}
            key="shipment-tab-Warning"
            label="Warning"
            value="Warning"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<BusinessIcon style={{ color: deepPurple[500] }} />}
            key="shipment-tab-Confirmed"
            label="Confirmed"
            value="Confirmed"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<CloseIcon style={{ color: red[300] }} />}
            key="shipment-tab-Cancelled"
            label="Cancelled"
            value="Cancelled"
          />
          <Tab
            wrapped
            className={`${classes.tabbed} ${
              selectedViaTab === ""
                ? "fedexup"
                : String(selectedViaTab).toLowerCase()
            }`}
            icon={<DoneAllOutlinedIcon style={{ color: green[500] }} />}
            key="shipment-tab-Delivered"
            label="Delivered"
            value="Delivered"
          />
        </Tabs>
        <Divider />
        <div>
          {filterValues.shipment_status === "Open" && (
            <OrderListDesktop
              ids={openIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Error" && (
            <OrderListDesktop
              ids={errorIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Printed" && (
            <OrderListDesktop
              ids={printedIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Warning" && (
            <OrderListDesktop
              ids={warningIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Confirmed" && (
            <OrderListDesktop
              ids={confirmedIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Cancelled" && (
            <OrderListDesktop
              ids={cancelledIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
          {filterValues.shipment_status === "Delivered" && (
            <OrderListDesktop
              ids={deliveredIds}
              selectedRow={selectedRowId}
              {...rest}
            />
          )}
        </div>
      </Fragment>
    </ThemeProvider>
  );
};

export default TabbedOrderDatagrid;
