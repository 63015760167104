import React, { FC } from "react";
import { FieldProps, Order } from "../types";
import { formatDate } from "../utils/date";

interface Props extends FieldProps<Order> {}

const ShipmentDateField: FC<Props> = ({ record }) => {
  return (
    <span>
      {record && record.shipment_date ? formatDate(record.shipment_date) : "-"}
    </span>
  );
};

ShipmentDateField.defaultProps = {
  label: "resources.order.fields.shipment_date",
  source: "shipment_date",
  addLabel: true,
};

export default ShipmentDateField;
