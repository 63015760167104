import React, { FC } from "react";
import { useTranslate } from "react-admin";
import { Order } from "../types";
import CardWithIcon from "./CardWithIcon";

interface Props {
  orders?: Order[];
  ship_via: string;
  status: string;
}

const getShipVia = (cargoType: string) => {
  if (cargoType.match(/\s?FEDEX\s?/gim)) return "fedex";
  if (cargoType.match(/\s?UPS\s?/gim)) return "ups";
  return "";
};

const ShipmentsList: FC<Props> = ({ orders = [], ship_via, status }) => {
  const translate = useTranslate();
  return (
    <CardWithIcon
      to={encodeURI(
        `/order/?filter=${JSON.stringify({
          ship_via: ship_via,
          shipment_status: status,
        })}`
      )}
      status={status}
      title={translate(`pos.dashboard.shipping_${getShipVia(ship_via)}`)}
      subtitle={orders.length}
    >
      {/* <List dense={true} className={classes.listWrapper}>
        {orders.map((record) => (
          <ListItem
            key={record.id}
            button
            component={Link}
            to={`/order/${record.id}/show?filter={"shipment_status"%3A"${status}"%2C"ship_via"%3A"${ship_via}"}`}
          >
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography variant="body2" color="primary">
                    {`${record.id}`}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                  >
                    <i>Nb. Items:</i>
                    <strong> {record.total_quantity}</strong>
                  </Typography>
                </React.Fragment>
              }
              //secondary={record.ship_via}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="caption"
                    // className={classes.quantity}
                    color="textPrimary"
                  >
                    {`${record.ship_via}`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List> */}
    </CardWithIcon>
  );
};

export default ShipmentsList;
