import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

import { FieldProps } from "../types";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    opacity: 0.87,
    whiteSpace: "nowrap",
    fontSize: "0.925rem",
    color: "#29007c",
    lineHeight: 1.5,
    marginLeft: "-5px",
    marginRight: "-5px",
    "&:hover,&:focus": {
      opacity: 1,
    },
    "&:focus": {
      backgroundColor: "rgba(209, 196, 233, 0.55)",
    },
  },
  spanned: {},
});

interface OwnProps {
  acumaticaURL?: "#";
}

const AcumaticaShipmentField: FC<FieldProps & OwnProps> = ({
  record,
  acumaticaURL = "https://tayse.acumatica.com/(W(28))/Main?CompanyID=Tayse&ScreenId=SO302000&ShipmentNbr=",
}) => {
  const classes = useStyles();
  const hrefAddress = record ? acumaticaURL + record.shipment_id : "#";

  return record ? (
    <Button
      variant="text"
      href={hrefAddress}
      className={classes.root}
      target="_blank"
      rel="noopener noreferrer"
      endIcon={<LaunchIcon />}
      size="small"
    >
      <span className={classes.spanned}>{record.shipment_id}</span>
    </Button>
  ) : null;
};

AcumaticaShipmentField.defaultProps = {
  label: "resources.order.fields.shipment_id",
  source: "shipment_id",
  addLabel: true,
};

export default AcumaticaShipmentField;
