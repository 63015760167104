import React from "react";
import { AppBar, UserMenu } from "react-admin";
import { Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: "0 0 auto",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#ff7043",
  },
  spacer: {
    flex: 1,
  },
  brand: {
    width: "auto",
    height: "40",
    borderRadius: "0",
    "& img": {
      objectFit: "contain",
    },
  },
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>{/* <ConfigurationMenu /> */}</UserMenu>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      ></Typography>
      <span className={classes.spacer} />
      <Avatar className={classes.brand} alt="TAYSE" src="/tayse-light.png" />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
