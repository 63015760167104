import React from "react";
import {
  deepOrange,
  deepPurple,
  green,
  grey,
  orange,
  red,
} from "@material-ui/core/colors";
import Chart from "react-apexcharts";

const chartOptions = {
  dataLabels: {
    enabled: true,
    style: {
      colors: ["#29007c", "#ffb406"],
    },
    offsetX: 0,
    background: {
      enabled: true,
      //foreColor: "#000",
      padding: 6,
      borderRadius: 0,
      borderWidth: 1,
      borderColor: "#515151",
      opacity: 0.9,
      dropShadow: {
        enabled: false,
      },
    },
    dropShadow: {
      enabled: true,
      top: 1,
      left: 1,
      blur: 3,
      color: "#000",
      opacity: 0.15,
    },
  },
  chart: {
    toolbar: {
      show: true,
    },
    sparkline: {
      enabled: false,
    },
    // height: "600px",
    // stacked: true,
    // stackType: '100%',
  },
  colors: ["#29007c", "#ffb406"],
  fill: {
    opacity: 0.85,
    colors: ["rgba(41, 0, 124, 0.65)", "rgba(255, 180, 6, 0.65)"],
  },
  grid: {
    strokeDashArray: "5",
    borderColor: "rgba(125, 138, 156, 0.3)",
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  legend: {
    show: true,
  },
  xaxis: {
    type: "category",
    categories: [
      "Open",
      "Error",
      "Printed",
      "Warning",
      "Confirmed",
      "Cancelled",
      "Delivered",
    ],
    labels: {
      style: {
        colors: [
          deepOrange[500],
          red[700],
          grey[700],
          orange[700],
          deepPurple[500],
          red[300],
          green[500],
        ],
        fontSize: "14px",
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: 500,
      },
      formatter: (value: any) => {
        return value;
      },
    },
  },
};

interface Props {
  chartData: object[];
}

const StatusStatsChart: React.FC<Props> = (props) => {
  const [chartData, setChartData] = React.useState<object[]>([]);

  React.useEffect(() => {
    setChartData(props.chartData);
  }, [props.chartData]);

  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type="bar"
      height={330}
    ></Chart>
  );
};

export default StatusStatsChart;
