import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";

import {
  Container,
  Typography,
  Divider,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";

import { Notification } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";
import { lightTheme } from "./themes";
import ForgotPasswordButton from "./CustomForgotPassword";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    background:
      "linear-gradient(135deg, #00023b 0%, #00023b 50%, #313264 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 360,
    maxWidth: 420,
    margin: "1em auto",
    borderRadius: 0,
    padding: "2rem",
  },
  avatar: {
    margin: "3rem auto 1em",
    display: "flex",
    justifyContent: "center",
    maxWidth: "18rem",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    margin: "1em auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.grey[500],
    flexWrap: "nowrap",
    textAlign: "center",
    alignItems: "center",
  },
  form: {
    margin: "2em auto",
    padding: "1em",
    display: "block",
  },
  formIn: {
    borderColor: "trasnparent",
    boxShadow: "0 4px 8px rgba(0,0,0,.05)",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  divide: {
    border: ".5px solid #0e123e",
    margin: "1rem auto",
    width: "90%",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    variant="outlined"
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();
  const copyright =
    "© " + new Date().getUTCFullYear() + " Tayse International Trading, Inc.";

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    localStorage.setItem("email", auth.username ? auth.username : "");
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error: Error) => {
        setLoading(false);
        localStorage.removeItem("email");
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      }
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Container maxWidth={false} className={classes.main}>
      <div className={classes.avatar}>
        <img alt="Tayse | Ship Flow Portal" src="/tayse-light.png" />
      </div>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <Card className={classes.card}>
                <div className={classes.hint}>
                  <LockIcon color="error" />
                  <span>Please login with your credentials.</span>
                </div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      // autoFocus
                      name="username"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.username")}
                      type="email"
                      disabled={loading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
                <div className={classes.hint}>
                  <ForgotPasswordButton />
                  <Divider className={classes.divide} flexItem />
                  <Typography
                    align="center"
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    {copyright}
                  </Typography>
                </div>
              </Card>
              <Notification />
            </div>
          </form>
        )}
      />
    </Container>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
