import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import { Close, CloudDownload } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { TextField } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  noGutter: {
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  downloadButton: {
    margin: `${theme.spacing(3)}px auto`,
  },
}));

interface EndOfDayDialogProps extends DialogProps {
  onClose: () => void;
}

const EndOfDayModal: React.FC<EndOfDayDialogProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [date, setDate] = useState<String>(
    new Date().toLocaleDateString("en-CA")
  );
  const { addToast } = useToasts();

  const handleDownload = () => {
    fetch(
      `${process.env.REACT_APP_HEROKU_API_URL}/endOfDay/download-report?date=${date}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
        throw addToast(translate("resources.end_of_day.error"), {
          appearance: "warning",
        });
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `end_of_day_${
          date || new Date().toLocaleDateString("en-CA")
        }.txt`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(() => {
        props.onClose();
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={props.open}
      onEntered={() => {}}
      keepMounted
      onBackdropClick={props.onClose}
      aria-labelledby="item-label-dialog-title"
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        {translate("resources.end_of_day.download_title")}
      </DialogTitle>
      <DialogContent dividers={true} className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <TextField
              id="date"
              label={translate("resources.end_of_day.date")}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: "2022-04-14",
                  max: new Date().toLocaleDateString("en-CA"),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              onClick={handleDownload}
              variant="outlined"
              color="primary"
              size="large"
              className={classes.downloadButton}
              endIcon={<CloudDownload fontSize="small" />}
            >
              {translate("ra.action.download")}
            </Button>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          size="small"
          endIcon={<Close fontSize="small" />}
        >
          {translate("ra.action.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndOfDayModal;
