import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { useTranslate } from "react-admin";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  noGutter: {
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  fedexDownloadButton: {
    width: "90%",
    margin: `${theme.spacing(1)}px auto`,
    backgroundColor: "#29007c",
    color: "#ff5a00",
  },
  upsDownloadButton: {
    width: "90%",
    margin: `${theme.spacing(1)}px auto`,
    backgroundColor: "#341b14",
    color: "#ffb406",
  },
  avatar: {
    borderRadius: "0",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    maxWidth: "100%",
    width: "64px",
    height: "32px",
    "& > img": {
      height: "32px",
      width: "100%",
      objectFit: "contain",
    },
    ".Mui-selected & > img": {
      opacity: "1",
    },
  },
}));

interface MorningBatchDialogProps extends DialogProps {
  onClose: () => void;
}

const MorningBatchModal: React.FC<MorningBatchDialogProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { addToast } = useToasts();
  const [date, setDate] = useState<String>(
    new Date().toLocaleDateString("en-CA")
  );

  useEffect(() => {
    const notifyToast = (message: string) => {
      addToast(message, {
        appearance: "error",
        autoDismiss: false,
      });
    };

    fetch(`${process.env.REACT_APP_HEROKU_API_URL}/morning-batch`, {
      method: "GET",
    })
      .then((r) => r.json())
      .then((response) => {
        const { fedexSingle, fedexMulti, upsSingle, upsMulti } = response;
        const message = "morning batch not downloaded for:";
        if (fedexSingle && fedexMulti && upsSingle && upsMulti) {
          if (fedexSingle.length > 0) {
            notifyToast(
              `FedEx-SinglePiece ${message} ${fedexSingle.join(", ")}`
            );
          }
          if (fedexMulti.length > 0) {
            notifyToast(`FedEx-MultiPiece ${message} ${fedexMulti.join(", ")}`);
          }
          if (upsSingle.length > 0) {
            notifyToast(`UPS-SinglePiece ${message} ${upsSingle.join(", ")}`);
          }
          if (upsMulti.length > 0) {
            notifyToast(`UPS-MultiPiece ${message} ${upsMulti.join(", ")}`);
          }
        }
      });
  }, [addToast]);

  const handleDownload = (carrier: string, type: string) => {
    fetch(
      `${process.env.REACT_APP_HEROKU_API_URL}/morning-batch?carrier=${carrier}&type=${type}&date=${date}`,
      {
        method: "GET",
      }
    )
      .then((r) => r.json())
      .then((response) => {
        if (response.downloadUrl) {
          var a = document.createElement("a");
          a.href = response.downloadUrl;
          a.download = `${carrier}-${new Date().toLocaleDateString(
            "en-CA"
          )}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          throw addToast(translate("resources.morning_batch.error"), {
            appearance: "warning",
          });
        }
      })
      .catch(() => {
        props.onClose();
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      scroll="paper"
      open={props.open}
      onEntered={() => {}}
      keepMounted
      onBackdropClick={props.onClose}
      aria-labelledby="item-label-dialog-title"
    >
      <DialogTitle
        id="item-label-dialog-title"
        className={classes.dialogTitle}
        color="primary"
      >
        {translate("resources.morning_batch.download_title")}
      </DialogTitle>
      <DialogContent dividers={true} className={classes.noGutter}>
        <Paper square variant="elevation">
          <div className={classes.container}>
            <TextField
              id="date"
              label={translate("resources.morning_batch.date")}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputProps={{
                inputProps: {
                  min: "2023-03-14",
                  max: new Date().toLocaleDateString("en-CA"),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid container>
              <Grid item xs={6}>
                <Grid item xs={12} justify="center" alignItems="center">
                  <Avatar src={`/svg/fedex.svg`} className={classes.avatar} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("FedEx", "SinglePiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.fedexDownloadButton}
                  >
                    Single Piece
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("FedEx", "MultiPiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.fedexDownloadButton}
                  >
                    Multi Piece
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12} justify="center" alignItems="center">
                  <Avatar src={`/svg/ups.svg`} className={classes.avatar} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("UPS", "SinglePiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.upsDownloadButton}
                  >
                    Single Piece
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleDownload("UPS", "MultiPiece")}
                    variant="outlined"
                    color="primary"
                    size="medium"
                    className={classes.upsDownloadButton}
                  >
                    Multi Piece
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          size="small"
          endIcon={<Close fontSize="small" />}
        >
          {translate("ra.action.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MorningBatchModal;
