import React, { useState } from "react";
import { useNotify } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { Close, Done } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { fetchUtils } from "ra-core";
import { addParamsToEndpoint } from "../utils/request";

const httpClient = fetchUtils.fetchJson;

interface UpdateStatusModalProps extends DialogProps {
  status: string;
  shipmentIds?: string;
  setShipmentIds: any;
  open: boolean;
  setOpen: any;
  setIsDone?: any;
}

const UpdateStatusModal: React.FC<UpdateStatusModalProps> = ({
  status,
  shipmentIds,
  setShipmentIds,
  open,
  setOpen,
  setIsDone,
}) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const notify = useNotify();

  const handleDialog = () => {
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setShipmentIds("");
  };

  const handleConfirm = async () => {
    setLoading(true);
    let options = {
      headers: new Headers({}),
    };
    const email = localStorage.getItem("email");
    options.headers.set("X-Email", email ? email : "");
    httpClient(
      addParamsToEndpoint(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/update-status`,
        {
          shipment_ids: shipmentIds,
          status,
        }
      ),
      options
    )
      .then((res) => {
        notify(
          "resources.order.actions.print.finished",
          "success",
          {},
          false,
          2000
        );
        if (setIsDone) {
          setIsDone(true);
        }
      })
      .catch((error) => {
        notify(
          "resources.order.actions.print.unknown",
          "error",
          {},
          false,
          2000
        );
      })
      .finally(() => {
        setLoading(false);
        if (setIsDone) {
          setIsDone(false);
        }
        handleClose();
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={open}
      onEntered={() => {}}
      onExited={handleDialog}
      keepMounted
      onBackdropClick={handleClose}
      aria-labelledby="item-label-dialog-title"
    >
      <DialogTitle id="title" color="primary" key={`title-${shipmentIds}`}>
        Update Shipment Status
      </DialogTitle>
      <DialogContent dividers={true}>
        {loading ? (
          <LinearProgress color="secondary" />
        ) : (
          <Alert severity="warning">
            Do you want to move the shipment as {status} ?
          </Alert>
        )}
      </DialogContent>
      <DialogActions role="close">
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: "1rem" }}
          endIcon={<Close fontSize="small" />}
        >
          Cancel
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          size="small"
          style={{
            backgroundColor: "#4BB543",
            marginRight: "1rem",
          }}
          endIcon={<Done fontSize="small" />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatusModal;
