import React, { FC, createElement } from "react";
import { Card, Box, Typography, Divider, Avatar } from "@material-ui/core";
import { makeStyles, lighten } from "@material-ui/core/styles";
import {
  deepOrange,
  deepPurple,
  grey,
  red,
  green,
  orange,
} from "@material-ui/core/colors";
import { Link } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ErrorIcon from "@material-ui/icons/Error";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from "@material-ui/icons/Close";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import PrintIcon from "@material-ui/icons/Print";
import WarningIcon from "@material-ui/icons/Warning";
import waveybg from "./wavey.svg";

interface Props {
  to: string;
  status?: string;
  title?: string;
  subtitle?: string | number;
}

const colorizeStatus = (shipment_status?: string) => {
  let colorized = "inherit";

  switch (shipment_status) {
    case "Open":
    case "Pickup Requested":
      colorized = deepOrange[500];
      break;
    case "Error":
      colorized = red[700];
      break;
    case "Printed":
      colorized = grey[700];
      break;
    case "Warning":
      colorized = orange[700];
      break;
    case "Confirmed":
    case "In Transit":
      colorized = deepPurple[500];
      break;
    case "Cancelled":
      colorized = red[300];
      break;
    case "Delivered":
      colorized = green[500];
      break;
    default:
      break;
  }

  return colorized;
};

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    boxShadow: "0 0 1rem rgba(0,0,0,.15) !important",
    marginBottom: theme.spacing(1),
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  borderedCard: (props: Props) => ({
    border: `1px solid ${lighten(colorizeStatus(props.status), 0.45)}`,
  }),
  main: (props: Props) => ({
    overflow: "inherit",
    padding: "16px 20px",
    background: `${lighten(
      colorizeStatus(props.status),
      0.95
    )} url(${waveybg}) -30px 0 no-repeat`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition:
      "background-position 0.15s ease-in 0s, background-color 0.15s ease-in 0s",
    "&:hover": {
      backgroundColor: "transparent!important",
      backgroundPositionX: "0px",
    },
    "& .icon": {
      color: colorizeStatus(props.status),
      display: "flex",
      flex: "1 0 50%",
      alignItems: "center",
      alignSelf: "center",
      "& svg": { marginRight: theme.spacing(1) },
    },
  }),
  avatar: {
    borderRadius: "0",
    marginRight: "auto",
    marginLeft: theme.spacing(1),
    maxWidth: "100%",
    width: "36px",
    height: "24px",
    "& > img": {
      height: "24px",
      maxWidth: "100%",
      objectFit: "contain",
    },
    ".Mui-selected & > img": {
      opacity: "1",
    },
  },
  title: {},
}));

const getStatusIcon = (status?: string) => {
  let icon = CheckBoxOutlineBlankIcon;

  switch (status) {
    case "Open":
    case "Pickup Requested":
      icon = ScheduleOutlinedIcon;
      break;
    case "Error":
      icon = ErrorIcon;
      break;
    case "Printed":
      icon = PrintIcon;
      break;
    case "Warning":
      icon = WarningIcon;
      break;
    case "Confirmed":
    case "In Transit":
      icon = BusinessIcon;
      break;
    case "Cancelled":
      icon = CloseIcon;
      break;
    case "Delivered":
      icon = DoneAllOutlinedIcon;
      break;
    default:
      break;
  }

  return icon;
};

const getShipVia = (cargoType: string) => {
  if (cargoType.match(/\s?FEDEX\s?/gim)) return "fedex";
  if (cargoType.match(/\s?UPS\s?/gim)) return "ups";
  return null;
};

const CardWithIcon: FC<Props> = (props) => {
  const { status, title, subtitle, to, children } = props;
  const classes = useStyles(props);
  return (
    <Card
      className={`${classes.borderedCard} ${classes.card}`}
      variant="outlined"
    >
      <Link to={to} title={status}>
        <div className={classes.main}>
          <Box className="icon">
            {createElement(getStatusIcon(status), { fontSize: "large" })}
            <Typography
              className={classes.title}
              variant="h6"
              color="textSecondary"
            >
              {status}
            </Typography>
          </Box>
          {children ? (
            <Avatar
              src={`/svg/${getShipVia(title as string)}.svg`}
              className={classes.avatar}
            />
          ) : null}
          <Box textAlign="right">
            {children ? null : (
              <Typography
                className={classes.title}
                variant="h6"
                color="textSecondary"
              >
                {title}
              </Typography>
            )}
            <Typography variant="h6" color="secondary">
              {subtitle !== undefined ? subtitle : ""}
            </Typography>
          </Box>
        </div>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
