import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

import { FieldProps } from "../types";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    opacity: 0.87,
    marginLeft: "-5px",
    marginRight: "-5px",
    whiteSpace: "nowrap",
    fontSize: "0.925rem",
    color: "#29007c",
    lineHeight: 1.5,
    "&:hover,&:focus": {
      opacity: 1,
    },
    "&:focus": {
      backgroundColor: "rgba(209, 196, 233, 0.55)",
    },
  },
  spanned: {},
});

interface OwnProps {
  acumaticaURL?: "#";
}

const AcumaticaOrderField: FC<FieldProps & OwnProps> = ({
  record,
  acumaticaURL = "https://tayse.acumatica.com/Main?CompanyID=Tayse&ScreenId=SO301000&OrderType=SO&OrderNbr=",
}) => {
  const classes = useStyles();
  const hrefAddress = record ? acumaticaURL + record.order_id : "#";

  return record ? (
    <Button
      variant="text"
      href={hrefAddress}
      className={classes.root}
      target="_blank"
      rel="noopener noreferrer"
      endIcon={<LaunchIcon />}
      size="small"
    >
      <span className={classes.spanned}>{record.order_id}</span>
    </Button>
  ) : null;
};

AcumaticaOrderField.defaultProps = {
  label: "resources.order.fields.order_id",
  source: "order_id",
  addLabel: true,
};

export default AcumaticaOrderField;
