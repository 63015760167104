// in src/App.tsx
import React, { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import { fetchUtils } from "ra-core";
import jsonServerProvider from "ra-data-json-server";

import boxes from "./boxes";
import orders from "./orders/";
import themeReducer from "./themeReducer";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";

// Translation
import englishMessages from "./i18n/en";

// Authenticaiton
import { Login, Layout } from "./layout";
import { config } from "./auth/firebase";

import * as firebase from "firebase/app";
import { FirebaseAuthProvider, RAFirebaseOptions } from "react-admin-firebase";
import customers from "./customers";
// import { Options } from "react-redux";

const firebaseApp = firebase.initializeApp(config);

const options: RAFirebaseOptions = {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ["/", "order", "box", "customer"],
  // dontwatch: ['comments'];
  persistence: "session",
  // disableMeta: true
  dontAddIdFieldToDoc: true,
};

const i18nProvider = polyglotI18nProvider((locale) => {
  // Always fallback on english
  return englishMessages;
}, "en");

// Send custom headers
const httpClient = (url?: any, options?: fetchUtils.Options) => {
  if (!options) {
    options = { headers: {} };
    options.headers = new Headers({
      Accept: "application/json",
      "Access-Control-Expose-Headers": "X-Total-Count",
    });
    const loggedInUserEmail = localStorage.getItem("email");
    options.headers.set("X-Email", loggedInUserEmail ? loggedInUserEmail : "");
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProviderInstance = jsonServerProvider(
  process.env.REACT_APP_HEROKU_API_URL,
  httpClient
);

const firebaseAuthProvider = FirebaseAuthProvider(config, options);

const App: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    dataProviderInstance !== undefined && setLoading(false);
  }, [loading]);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <Admin
      title="Tayse International Trading Batch System"
      dataProvider={dataProviderInstance}
      authProvider={firebaseAuthProvider}
      loginPage={Login}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      layout={Layout}
      i18nProvider={i18nProvider}
    >
      <Resource name="order" {...orders} />
      <Resource name="box" {...boxes} />
      <Resource name="customer" {...customers} />
    </Admin>
  );
};

export default App;
