import React from "react";
import { useTranslate } from "react-admin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactsIcon from "@material-ui/icons/Contacts";

import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";
import { Order } from "../types";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  accordionSummary: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionDetails: {
    fontSize: "0.875rem",
    flexDirection: "column",
    overflow: "hidden",
    width: "auto",
    padding: "12px 16px",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  purpleInvert: {
    color: deepPurple[500],
    backgroundColor: "transparent",
  },
  paper: {
    margin: "-8px -12px",
    width: "calc(100% + 24px)",
    backgroundColor: theme.palette.background.paper,
  },
  featuresGrid: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& *": {
      letterSpacing: "0.01071em",
      pointerEvents: "none",
      marginLeft: 0,
    },
    padding: "6px 8px",
  },
}));

const customerDetais: string[] = [
  "customer_order_number",
  "customer_reference_number",
  "contact_attention",
  "contact_email",
  "contact_phone",
  "contact_company_name",
];

interface Props {
  orderRecord: Order;
  fetching: Boolean;
}

const CustomerAccordion: React.FC<Props> = ({
  orderRecord,
  fetching = false,
  ...others
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Accordion
      key="customer-accordion-root"
      TransitionProps={{ unmountOnExit: true }}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="customer-content"
        id="customer-header"
        className={classes.accordionSummary}
      >
        <Avatar className={`${classes.purpleInvert} ${classes.small}`}>
          <ContactsIcon />
        </Avatar>
        <Typography className={classes.heading}>Customer</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        aria-expanded={false}
      >
        {fetching ? (
          <LinearProgress color="secondary" />
        ) : (
          <Paper className={classes.paper}>
            {customerDetais.map((row) => (
              <Grid
                className={classes.featuresGrid}
                container
                wrap="wrap"
                key={`customer-row-${row}`}
              >
                <Grid item xs={6} zeroMinWidth>
                  <Typography variant="subtitle2" color="textPrimary" noWrap>
                    {translate(`resources.order.fields.${row}`)}
                  </Typography>
                </Grid>
                <Grid item xs={6} zeroMinWidth>
                  <Typography
                    variant="body2"
                    align="right"
                    color="textSecondary"
                    noWrap
                  >
                    {orderRecord[row]}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomerAccordion;
