import React, { FC, Fragment, useCallback } from "react";
import {
  Button,
  List,
  Pagination,
  useUnselectAll,
  useTranslate,
} from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";

import { ToastProvider } from "react-toast-notifications";

import classnames from "classnames";

import { Drawer, Theme, ThemeProvider, Grid } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { PaginationProps } from "@material-ui/lab";
import { CloudDownload } from "@material-ui/icons";

import EndOfDayModal from "../components/EndOfDayModal";
import PrintModal from "../components/PrintModal";
import PrintInvoiceModal from "../components/PrintInvoice";
import UpdateStatusModal from "../components/UpdateStatusModal";
import MorningBatchModal from "../components/MorningBatchModal";

import CorrectShipmentsButton from "./CorrectShipmentsButton";
import OrderShow from "./OrderShow";
import OrderFilter from "./OrderFilter";
import PrintShipmentsButton from "./PrintShipmentsButton";
import TabbedOrderDatagrid from "./TabbedOrderDatagrid";

import { BulkActionProps, ListComponentProps } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  end_of_day: {
    display: "flex",
    paddingLeft: 10,
  },
  morning_batch_button: {
    marginRight: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  list: {
    flexGrow: 1,
    paddingRight: "0.5em",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    overflowX: "hidden",
    overflowBlock: "scroll",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  purpleInvert: {
    color: deepPurple[500],
    backgroundColor: "transparent",
  },
}));

const raThemeProvider = {
  // breakpoints: {},
  overrides: {
    // Toolbar custom styles
    // MuiInputBase: { root: { color: "rgba(0,0,0,1)" } },
    MuiFormLabel: { root: { fontSize: ".75rem", color: "rgba(51,51,51,1)" } },
    MuiSelect: {
      select: { fontWeight: "500" },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(67, 149, 255, 0.1)",
        fontSize: ".75rem",
        "&:hover": {
          backgroundColor: "rgba(67, 149, 255, 0.25)",
          color: "rgba(0,0,0,1)",
        },
        "&.Mui-focused": { backgroundColor: "rgba(255, 255, 255, 085)" },
      },
      // input: { color: "rgba(0,0,0,1)" },
    },
    RaFilterForm: {
      form: {
        marginTop: "0px",
        minHeight: "60px",
      },
    },
    RaSearchInput: {
      input: {
        marginTop: "28px",
      },
    },
    RaListToolbar: {
      actions: {
        flex: "0 0 auto",
        minHeight: "40px",
        paddingTop: "18px",
      },
    },
    RaTopToolbar: {
      root: { minHeight: "32px", paddingTop: "8px", paddingBottom: "8px" },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        color: "#2b1a1a",
        height: "64px",
        minHeight: "64px",
        backgroundColor: "rgb(136, 195, 225)",
      },
    },
  },
};

interface OrderListActionsProps extends BulkActionProps {
  selectedTab: string;
}

const OrderList: FC<ListComponentProps<{ id: string }>> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = React.useState<boolean>(
    false
  );
  const [hideModalOpen, setHideModalOpen] = React.useState<boolean>(false);
  const [endOfDayModalOpen, setEndOfDayModalOpen] = React.useState<boolean>(
    false
  );
  const [morningBatchModalOpen, setMorningBatchModalOpen] = React.useState<
    boolean
  >(false);
  const [shipmentIds, setshipmentIds] = React.useState<string | undefined>();
  const [shipmentId, setShipmentId] = React.useState<string | number>();
  const [shipments, setShipments] = React.useState<string | undefined>();
  const [pdfSource, setPdfSource] = React.useState<string | undefined>();
  const [selectedStatusTab, setSelectedStatusTab] = React.useState<string>(
    "Open"
  );
  const [selectedViaTab, setSelectedViaTab] = React.useState<any>("");

  const unselectAll = useUnselectAll("order");

  const handleModalClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      //! TODO: Call another function --> reason?
    }
    setModalOpen(false);
    setshipmentIds(undefined);
  };

  const handleShipmentPrint = (
    modalOpen: boolean,
    shipmentsArray: string,
    pdfSource?: string
  ) => {
    setModalOpen(modalOpen);
    setshipmentIds(shipmentsArray);
    setPdfSource(pdfSource);
  };

  const handleUpdateShipmentStatus = (
    modalOpen: boolean,
    shipmentsArray: string
  ) => {
    setHideModalOpen(modalOpen);
    setShipments(shipmentsArray);
  };

  const handleShipmentInvoice = (
    modalOpen: boolean,
    shipment_id: string | number
  ) => {
    setInvoiceModalOpen(modalOpen);
    setShipmentId(shipment_id);
  };

  const handleInvoiceModalClose = () => {
    setInvoiceModalOpen(false);
    setShipmentId(undefined);
  };

  const handleStatusTab = (viaTab: string, statusTab: string) => {
    setSelectedViaTab(viaTab);
    setSelectedStatusTab(statusTab);
  };

  const handleClose = useCallback(() => {
    history.replace("/order");
  }, [history]);

  React.useEffect(() => {
    unselectAll();
    handleClose();
  }, [unselectAll, selectedViaTab, selectedStatusTab, handleClose]);

  const OrderBulkActionButtons: FC<OrderListActionsProps> = (props) => (
    <ThemeProvider theme={raThemeProvider}>
      <Fragment>
        {props.selectedTab === "Open" ? (
          <PrintShipmentsButton {...props} />
        ) : null}
        {props.selectedTab === "Error" ? (
          <CorrectShipmentsButton {...props} />
        ) : null}
      </Fragment>
    </ThemeProvider>
  );

  const PostPagination = (props: PaginationProps) => (
    <Grid container direction="row">
      <Grid item sm={3} md={3} lg={5} className={classes.end_of_day}>
        <Button
          variant="contained"
          size="small"
          className={classes.morning_batch_button}
          label={translate("resources.morning_batch.print")}
          onClick={() => setMorningBatchModalOpen(true)}
        >
          <CloudDownload />
        </Button>
        <Button
          color="secondary"
          label={translate("resources.end_of_day.report")}
          onClick={() => setEndOfDayModalOpen(true)}
        >
          <CloudDownload />
        </Button>
      </Grid>
      <Grid item sm={3} md={9} lg={7}>
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Route path="/order/:id">
        {({ match }: RouteChildrenProps<{ id: string; action: string }>) => {
          let isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );

          return (
            <Fragment>
              <ThemeProvider theme={raThemeProvider}>
                <List
                  {...props}
                  bulkActionButtons={
                    <OrderBulkActionButtons selectedTab={selectedStatusTab} />
                  }
                  className={classnames(classes.list)}
                  sort={{ field: "print", order: "ASC" }}
                  pagination={<PostPagination />}
                  perPage={100}
                  filters={<OrderFilter />}
                  filterDefaultValues={{
                    shipment_status: "Open",
                    ship_via: "",
                    shipment_type: "",
                  }}
                >
                  <TabbedOrderDatagrid
                    selectedRowId={
                      isMatch ? ((match as any).params.id as string) : undefined
                    }
                    handleStatusTab={handleStatusTab}
                  />
                </List>
              </ThemeProvider>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                elevation={3}
                onClose={handleClose}
                PaperProps={{
                  className: classes.drawerPaper, // React-admin passes the classes to the <Paper>
                }}
              >
                {isMatch ? (
                  <OrderShow
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    onPrint={handleShipmentPrint}
                    onInvoicePrint={handleShipmentInvoice}
                    onHide={handleUpdateShipmentStatus}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
      <PrintModal
        id="label-modal"
        items={shipmentIds}
        pdfSource={pdfSource}
        open={modalOpen}
        onClose={handleModalClose}
      />
      <PrintInvoiceModal
        id="invoice-modal"
        shipmentId={shipmentId}
        open={invoiceModalOpen}
        onClose={handleInvoiceModalClose}
      />
      <UpdateStatusModal
        id="update-status-modal"
        status="Partial Movement"
        shipmentIds={shipments}
        setShipmentIds={setShipments}
        open={hideModalOpen}
        setOpen={setHideModalOpen}
      />
      <ToastProvider autoDismiss placement="bottom-center">
        <MorningBatchModal
          id="morning-batch-modal"
          open={morningBatchModalOpen}
          onClose={() => setMorningBatchModalOpen(false)}
        />
        <EndOfDayModal
          id="end-of-day-modal"
          open={endOfDayModalOpen}
          onClose={() => {
            setEndOfDayModalOpen(false);
          }}
        />
      </ToastProvider>
    </div>
  );
};

export default OrderList;
