import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { FieldProps, Order } from "../types";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: "0",
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    maxWidth: "100%",
    width: "36px",
    height: "32px",
    "& > img": {
      height: "28px",
      width: "100%",
      objectFit: "contain",
    },
  },
}));

interface Props extends FieldProps {
  ship_via?: Order["ship_via"];
  className?: string;
}

const getCargoIconSrc = (cargoType: string) => {
  if (cargoType.match(/\s?FEDEX\s?/)) return "fedex";
  if (cargoType.match(/\s?UPS\s?/)) return "ups";
  return null;
};

const getCargoCategory = (cargoType: string) => {
  return cargoType.replace(/\s?(FEDEX|UPS)\s+/gim, "");
};

const CargoAvatarField: React.FC<Props> = ({ ship_via, className }) => {
  const classes = useStyles();
  const cargoIconSrc = getCargoIconSrc(ship_via as string);
  const cargoCategory = getCargoCategory(ship_via as string);

  return (
    <div className={className}>
      <Avatar src={`/svg/${cargoIconSrc}.svg`} className={classes.avatar} />
      <Typography variant="caption" align="right" noWrap>
        {cargoCategory}
      </Typography>
    </div>
  );
};

export default CargoAvatarField;
