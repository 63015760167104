import React from "react";
import { useTranslate } from "react-admin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";
import {
  deepPurple,
  red,
  grey,
  blueGrey,
  orange,
} from "@material-ui/core/colors";

import { Order } from "../types";
import CargoTrackingNumberField from "../orders/CargoTrackingNumberField";
import ShippingCargoField from "../orders/ShippingCargoField";

import { formatDate } from "../utils/date";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  accordionSummary: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionSummaryPrinted: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionSummaryConfirmed: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionSummaryDelivered: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionSummaryWarning: {
    backgroundColor: orange["A100"],
    "&.Mui-expanded": {
      backgroundColor: orange["A200"],
    },
  },
  accordionSummaryError: {
    backgroundColor: red["A100"],
    "&.Mui-expanded": {
      backgroundColor: red["A200"],
    },
  },
  accordionSummaryCancelled: {
    backgroundColor: red[100],
    "&.Mui-expanded": {
      backgroundColor: red[200],
    },
  },
  accordionDetails: {
    fontSize: "0.875rem",
    flexDirection: "column",
    overflow: "hidden",
    width: "auto",
    padding: "12px 16px",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  purpleInvert: {
    color: deepPurple[500],
    backgroundColor: "transparent",
  },
  paper: {
    margin: "-8px -12px",
    width: "calc(100% + 24px)",
    backgroundColor: theme.palette.background.paper,
  },
  addrsDivider: {
    margin: theme.spacing(1, 0),
  },
  featuresGrid: {
    alignItems: "center",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& *": {
      letterSpacing: "0.01071em",
    },
    padding: "6px 8px",
  },
  avatarFlex: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  chipper: {
    flex: "0 0 auto",
    marginLeft: "auto",
    color: theme.palette.getContrastText(blueGrey[100]),
    backgroundColor: blueGrey[50],
    borderRadius: "0",
  },
  secondaryActButtonWrapper: {
    margin: `0px ${theme.spacing(1)}px 0px auto`,
    position: "relative",
    display: "flex",
    height: "36px",
    width: "36px",
  },
  buttonProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -16,
    marginLeft: -16,
  },
  buttonCancelProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  secondaryActRenewButton: {
    color: "#673ab7",
    backgroundColor: "rgb(255, 255, 255)",
    width: "auto",
    height: "auto",
    padding: "8px",
    "&:hover,&:active,&:focus": {
      color: red[500],
      backgroundColor: grey[100],
    },
    "&[disabled]": {
      color: red[100],
      backgroundColor: grey[300],
    },
  },
  secondaryActCancelButton: {
    color: "#fff",
    backgroundColor: "rgb(223, 92, 92)",
    width: "auto",
    height: "auto",
    padding: "2px",
    "&:hover,&:active,&:focus": {
      color: red[500],
      backgroundColor: grey[100],
    },
    "&[disabled]": {
      color: red[100],
      backgroundColor: grey[300],
    },
  },
}));

const TEMP_CANCEL_ALLOWED_EMAILS = [
  "deniz.kaplan@tayse.com",
  "yigit.altintas@tayse.com",
  "emin.yildirim@tayse.com",
];

interface Props {
  orderRecord: Order;
  renewShipment: (ship_via: Order["ship_via"]) => void;
  correctShipment: (shipment_ids: string) => void;
  cancelShipment: () => void;
  moveShipment: (shipment_id: Order["shipment_id"]) => void;
  deleteShipment: (shipment_id: Order["shipment_id"]) => void;
  fetching: boolean;
}

const ShipmentAccordion: React.FC<Props> = ({
  orderRecord,
  fetching = false,
  correctShipment,
  renewShipment,
  cancelShipment,
  moveShipment,
  deleteShipment,
  ...others
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [email] = React.useState(localStorage.getItem("email") || "");

  const handleDialogOpen = () => {
    setAlertOpen(true);
  };

  const handleCloseYes = () => {
    setAlertOpen(false);
    cancelShipment();
  };

  const handleDeleteShipment = (shipment_id: string | number | null) => {
    setDeleteDialogOpen(false);
    if (shipment_id) {
      deleteShipment(shipment_id);
    }
  };

  const handleCloseNo = () => {
    setAlertOpen(false);
  };

  return (
    <Accordion
      key="shipment-accordion-root"
      TransitionProps={{ unmountOnExit: true }}
      square={true}
      defaultExpanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="shipment-content"
        id="shipment-header"
        className={classes.accordionSummary}
      >
        <Avatar className={`${classes.purpleInvert} ${classes.small}`}>
          <LocalShippingIcon />
        </Avatar>
        <Typography className={classes.heading}>Shipment</Typography>
        {orderRecord.status === "Error" ? (
          <div className={classes.secondaryActButtonWrapper}>
            <Tooltip
              title="Refresh Shipment"
              aria-label="refresh-shipments-tracking-button"
            >
              <span>
                <IconButton
                  className={classes.secondaryActRenewButton}
                  aria-label="autorenew-shipments-tracking-label"
                  disabled={fetching}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  onClick={(event) => {
                    event.stopPropagation();
                    correctShipment([orderRecord.shipment_id].toString());
                  }}
                >
                  <AutorenewIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            {fetching && (
              <Fade
                in={fetching}
                style={{
                  transitionDelay: fetching ? "250ms" : "600ms",
                }}
                unmountOnExit
              >
                <CircularProgress
                  size={32}
                  className={classes.buttonProgress}
                />
              </Fade>
            )}
          </div>
        ) : null}
        <Chip
          icon={<ArrowRightIcon fontSize="small" />}
          size="small"
          className={classes.chipper}
          label={orderRecord.status}
          variant="outlined"
        />
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        aria-expanded={true}
      >
        <Paper className={classes.paper}>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-shipment_id"
          >
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.shipment_id`)}
              </Typography>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <Typography
                variant="body2"
                align="right"
                color="textSecondary"
                noWrap
              >
                {orderRecord.shipment_id}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-shipment_date"
          >
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.shipment_date`)}
              </Typography>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <Typography
                variant="body2"
                align="right"
                color="textSecondary"
                noWrap
              >
                {orderRecord.shipment_date
                  ? formatDate(orderRecord.shipment_date)
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-ship_via"
          >
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.ship_via`)}
              </Typography>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <ShippingCargoField
                addLabel={false}
                record={orderRecord}
                className={classes.avatarFlex}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-cargo_tracking_number"
          >
            <Grid item xs={4} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.cargo_tracking_number`)}
              </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth>
              {orderRecord.status === "Cancelled" && (
                <div
                  style={{
                    display: "inline-flex",
                    position: "relative",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <Tooltip
                    title="Delete Shipment"
                    aria-label="delete-shipment-button"
                  >
                    <span>
                      <IconButton
                        className={classes.secondaryActCancelButton}
                        aria-label="delete-shipment-button"
                        disabled={fetching}
                        disableRipple={true}
                        disableFocusRipple={true}
                        disableTouchRipple={true}
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  {fetching && (
                    <Fade
                      in={fetching}
                      style={{
                        transitionDelay: fetching ? "250ms" : "600ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress
                        size={24}
                        className={classes.buttonCancelProgress}
                      />
                    </Fade>
                  )}
                  <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    aria-labelledby="delete-shipment-alert-dialog-title"
                    aria-describedby="delete-shipment-alert-dialog-description"
                  >
                    <DialogTitle id="delete-shipment-alert-dialog-title">
                      Delete Shipment Confirmation
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        id="delete-shipment-alert-dialog-description"
                        color="error"
                      >
                        This action will delete the shipment from the batch.
                        Please confirm. You must delete the{" "}
                        <a
                          href={`https://tayse.acumatica.com/(W(59))/Main?CompanyID=Tayse&ScreenId=SO302000&ShipmentNbr=${orderRecord.shipment_id}`}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          associated shipment
                        </a>{" "}
                        document in Acumatica.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() =>
                          handleDeleteShipment(orderRecord.shipment_id)
                        }
                        color="primary"
                        autoFocus
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              {TEMP_CANCEL_ALLOWED_EMAILS.includes(email) &&
              (orderRecord.status === "Pickup Requested" ||
                orderRecord.status === "Printed" ||
                orderRecord.status === "Warning") &&
              orderRecord.cargo_tracking_number ? (
                <div
                  style={{
                    display: "inline-flex",
                    position: "relative",
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <Tooltip
                    title="Cancel Shipment"
                    aria-label="cancel-shipments-tracking-button"
                  >
                    <span>
                      <IconButton
                        className={classes.secondaryActCancelButton}
                        aria-label="cancel-shipments-tracking-label"
                        disabled={fetching}
                        disableRipple={true}
                        disableFocusRipple={true}
                        disableTouchRipple={true}
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDialogOpen();
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  {fetching && (
                    <Fade
                      in={fetching}
                      style={{
                        transitionDelay: fetching ? "250ms" : "600ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress
                        size={24}
                        className={classes.buttonCancelProgress}
                      />
                    </Fade>
                  )}
                  <Dialog
                    open={alertOpen}
                    onClose={handleCloseNo}
                    aria-labelledby="cancel-shipment-alert-dialog-title"
                    aria-describedby="cancel-shipment-alert-dialog-description"
                  >
                    <DialogTitle id="cancel-shipment-alert-dialog-title">
                      Cancel Shipment Confirmation
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        id="cancel-shipment-alert-dialog-description"
                        color="error"
                      >
                        Are you sure?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseNo} color="primary">
                        No
                      </Button>
                      <Button
                        onClick={handleCloseYes}
                        color="primary"
                        autoFocus
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : (
                orderRecord.status === "Error" &&
                orderRecord.error_message === "User Cancellation" && (
                  <div className={classes.secondaryActButtonWrapper}>
                    <Tooltip
                      title="Permanently Cancel Shipment"
                      aria-label="move-shipment-cancelled-button"
                    >
                      <span>
                        <IconButton
                          className={classes.secondaryActCancelButton}
                          aria-label="move-shipment-cancelled-label"
                          disabled={fetching}
                          disableRipple={true}
                          disableFocusRipple={true}
                          disableTouchRipple={true}
                          onClick={(event) => {
                            event.stopPropagation();
                            moveShipment([orderRecord.shipment_id].toString());
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {fetching && (
                      <Fade
                        in={fetching}
                        style={{
                          transitionDelay: fetching ? "250ms" : "600ms",
                        }}
                        unmountOnExit
                      >
                        <CircularProgress
                          size={32}
                          className={classes.buttonProgress}
                        />
                      </Fade>
                    )}
                  </div>
                )
              )}
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              {!orderRecord.cargo_tracking_number ? (
                <div className={classes.secondaryActButtonWrapper}>
                  <Tooltip
                    title="Get Tracking Nmbr."
                    aria-label="refresh-shipments-tracking-button"
                  >
                    <span>
                      <IconButton
                        className={classes.secondaryActRenewButton}
                        aria-label="autorenew-shipments-tracking-label"
                        disabled={fetching}
                        disableRipple={true}
                        disableFocusRipple={true}
                        disableTouchRipple={true}
                        onClick={(event) => {
                          event.stopPropagation();
                          renewShipment(orderRecord.ship_via);
                        }}
                      >
                        <LocalShippingIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  {fetching && (
                    <Fade
                      in={fetching}
                      style={{
                        transitionDelay: fetching ? "250ms" : "600ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress
                        size={32}
                        className={classes.buttonProgress}
                      />
                    </Fade>
                  )}
                </div>
              ) : (
                <Typography
                  variant="body2"
                  align="right"
                  color="textSecondary"
                  noWrap
                >
                  <CargoTrackingNumberField
                    addLabel={true}
                    record={orderRecord}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-shipment_cost"
          >
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.shipment_cost`)}
              </Typography>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <Typography
                variant="body2"
                align="right"
                color="textSecondary"
                noWrap
              >
                {orderRecord.shipment_cost}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresGrid}
            container
            wrap="wrap"
            key="shipment-row-shipment_address"
          >
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="subtitle2" color="textPrimary" noWrap>
                {translate(`resources.order.fields.shipment_address`)}
              </Typography>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <Typography variant="body2" align="right" color="textSecondary">
                {orderRecord.address_line1}
              </Typography>
              <Typography variant="body2" align="right" color="textSecondary">
                {orderRecord.address_line2}
              </Typography>
              <Typography variant="body2" align="right" color="textSecondary">
                {orderRecord.address_line3}
              </Typography>
              <Divider className={classes.addrsDivider} light />
              <Typography variant="body2" align="right" color="textSecondary">
                {orderRecord.city} {orderRecord.postal_code},{" "}
                {orderRecord.state}, {orderRecord.country}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ShipmentAccordion;
