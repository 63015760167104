import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery, Theme, ThemeProvider } from "@material-ui/core";

import { useTranslate, DashboardMenuItem, MenuItemLink } from "react-admin";

import orders from "../orders";
import partialMovements from "../partial-movements";
import boxes from "../boxes";
import { AppState } from "../types";
import customers from "../customers";

const raThemeProvider = {
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "2px solid rgb(152, 188, 206)",
        borderBottom: "1px solid rgb(30, 40, 45)",
        color: "rgb(231, 231, 231)",
        "&:hover": {
          backgroundColor: "rgba(231, 231, 231, 0.15)",
        },
      },
      active: {
        backgroundColor: "rgba(255, 112, 67, 0.1)",
        borderLeftColor: "rgb(255, 112, 67)!important",
        color: "rgb(231, 231, 231)",
        "& svg": {
          color: "#ff7043!important",
          fill: "#ff7043!important",
        },
        // MuiListItemIcon: { root: { color: "#ff7043" } },
        // MuiSvgIcon: { root: { fill: "#ff7043" } },
      },
    },
    MuiListItem: {
      root: {
        borderLeft: "3px solid rgb(152, 188, 206)",
        color: "rgb(201, 201, 201)",
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(211, 211, 211, 0.15)",
        },
      },
      gutters: {
        paddingLeft: "0.75rem",
        paddingRight: "0.75rem",
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: "0.75rem",
        paddingBottom: "0.75rem",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "rgb(207, 217, 222)",
        minWidth: "auto",
      },
    },
  },
};

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  return (
    <ThemeProvider theme={raThemeProvider}>
      <div>
        {" "}
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
        <MenuItemLink
          to={`/order`}
          primaryText={translate(`pos.menu.order`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/partial-movements`}
          primaryText={translate(`pos.menu.partial_movements`)}
          leftIcon={<partialMovements.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/box`}
          primaryText={translate(`resources.box.name`, {
            smart_count: 2,
          })}
          leftIcon={<boxes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/customer`}
          primaryText={translate(`resources.customer.name`, {
            smart_count: 2,
          })}
          leftIcon={<customers.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {isXSmall && logout}
      </div>
    </ThemeProvider>
  );
};

export default Menu;
