import React, { useState, useEffect, useCallback } from "react";
import { useDataProvider, usePermissions, useLoading } from "react-admin";
import Welcome from "./Welcome";
import { Order } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import ShipmentsList from "./ShipmentsList";
import DashboardCard from "./DashboardCard";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import StatusStatsChart from "./StatusStatsChart";

interface Shipments {
  openFedex: Order[];
  errorFedex: Order[];
  printedFedex: Order[];
  confirmedFedex: Order[];
  warningFedex: Order[];
  cancelledFedex: Order[];
  deliveredFedex: Order[];
  openUps: Order[];
  errorUps: Order[];
  printedUps: Order[];
  warningUps: Order[];
  confirmedUps: Order[];
  cancelledUps: Order[];
  deliveredUps: Order[];
}

interface StatusStats {
  open: number;
  error: number;
  printed: number;
  warning: number;
  confirmed: number;
  cancelled: number;
  delivered: number;
}

interface State {
  fedexStats: StatusStats;
  openFedex: Order[];
  errorFedex: Order[];
  printedFedex: Order[];
  warningFedex: Order[];
  confirmedFedex: Order[];
  cancelledFedex: Order[];
  deliveredFedex: Order[];
  upsStats: StatusStats;
  openUps: Order[];
  errorUps: Order[];
  printedUps: Order[];
  warningUps: Order[];
  confirmedUps: Order[];
  cancelledUps: Order[];
  deliveredUps: Order[];
}

const useStyles = makeStyles((theme) => ({
  dashboardWrapper: { padding: theme.spacing(2) },
  dashboardLoaderWrapper: { padding: `${theme.spacing(2)}px 0px` },
  dashboardSection: { margin: `${theme.spacing(2)}px ${theme.spacing(0)}` },
  dashboardSectionFedex: {
    paddingTop: "32px",
    margin: "2rem auto",
    background: "transparent url('/svg/fedex.svg') no-repeat 4px 2px scroll",
    backgroundSize: "auto 1.75rem",
  },
  dashboardSectionUps: {
    paddingTop: "32px",
    margin: "2rem auto",
    background: "transparent url('/svg/ups.svg') no-repeat 4px 2px scroll",
    backgroundSize: "1.75rem auto",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  datepicker: {
    marginRight: theme.spacing(2),
  },
}));

const dateFns = new DateFnsUtils();

const Dashboard: React.FC = (props) => {
  const classes = useStyles();
  const [state, setState] = useState<State>();
  const dataProvider = useDataProvider();
  const userPermissions = usePermissions();
  const loading = useLoading();

  const today = dateFns.date();
  const [selectedStartDate, setStartDate] = React.useState<Date>(
    dateFns.addDays(today, -30)
  );
  const [selectedEndDate, setEndDate] = React.useState<Date>(
    dateFns.addDays(today, 1)
  );
  const [shipmentPeriod, setShipmentPeriod] = React.useState<number>(30);

  const hadleIntervalChange = (
    event: React.ChangeEvent<{ name?: string | number; value: unknown }>
  ) => {
    const selectedPeriod = event.target.value as number;
    switch (selectedPeriod) {
      case 10:
        setShipmentPeriod(10);
        setStartDate(dateFns.addDays(today, -1));
        setEndDate(dateFns.addDays(today, 1));
        break;
      case 20:
        setShipmentPeriod(20);
        setStartDate(dateFns.addDays(today, -7));
        setEndDate(dateFns.addDays(today, 1));
        break;
      case 30:
        setShipmentPeriod(30);
        setStartDate(dateFns.addDays(today, -30));
        setEndDate(dateFns.addDays(today, 1));
        break;
      default:
        setShipmentPeriod(0);
        break;
    }
  };

  const fetchShipments = useCallback(async () => {
    const defaultShipments: Shipments = {
      openFedex: [],
      errorFedex: [],
      printedFedex: [],
      warningFedex: [],
      confirmedFedex: [],
      cancelledFedex: [],
      deliveredFedex: [],
      openUps: [],
      errorUps: [],
      printedUps: [],
      warningUps: [],
      confirmedUps: [],
      cancelledUps: [],
      deliveredUps: [],
    };

    const { data: shipments } = await dataProvider
      .getList("dashboard/shipments", {
        filter: {
          start_date: selectedStartDate.toLocaleDateString("en"),
          end_date: selectedEndDate.toLocaleDateString("en"),
        },
        sort: { field: "id", order: "DESC" },
        pagination: { page: 1, perPage: 50 },
      })
      .catch((e: any) => {});

    const aggregations: Shipments =
      shipments.data !== undefined
        ? Array.isArray(shipments.data)
          ? shipments.data.reduce((stats: Shipments, order: Order) => {
              if ((order.ship_via as string).match(/\s?(FEDEX)\s+/gim)) {
                if (
                  order.status === "Open" ||
                  order.status === "Pickup Requested"
                ) {
                  stats.openFedex.push(order);
                } else if (order.status === "Error") {
                  stats.errorFedex.push(order);
                } else if (order.status === "Printed") {
                  stats.printedFedex.push(order);
                } else if (order.status === "Warning") {
                  stats.warningFedex.push(order);
                } else if (
                  order.status === "Confirmed" ||
                  order.status === "In Transit"
                ) {
                  stats.confirmedFedex.push(order);
                } else if (order.status === "Cancelled") {
                  stats.cancelledFedex.push(order);
                } else if (order.status === "Delivered") {
                  stats.deliveredFedex.push(order);
                }
              }

              if ((order.ship_via as string).match(/\s?(UPS)\s+/gim)) {
                if (
                  order.status === "Open" ||
                  order.status === "Pickup Requested"
                ) {
                  stats.openUps.push(order);
                } else if (order.status === "Error") {
                  stats.errorUps.push(order);
                } else if (order.status === "Printed") {
                  stats.printedUps.push(order);
                } else if (order.status === "Warning") {
                  stats.warningUps.push(order);
                } else if (
                  order.status === "Confirmed" ||
                  order.status === "In Transit"
                ) {
                  stats.confirmedUps.push(order);
                } else if (order.status === "Cancelled") {
                  stats.cancelledUps.push(order);
                } else if (order.status === "Delivered") {
                  stats.deliveredUps.push(order);
                }
              }

              return stats;
            }, defaultShipments)
          : defaultShipments
        : defaultShipments;

    const fedexStats: StatusStats = {
      open: aggregations.openFedex.length,
      error: aggregations.errorFedex.length,
      printed: aggregations.printedFedex.length,
      warning: aggregations.warningFedex.length,
      confirmed: aggregations.confirmedFedex.length,
      cancelled: aggregations.cancelledFedex.length,
      delivered: aggregations.deliveredFedex.length,
    };

    const upsStats: StatusStats = {
      open: aggregations.openUps.length,
      error: aggregations.errorUps.length,
      printed: aggregations.printedUps.length,
      warning: aggregations.warningUps.length,
      confirmed: aggregations.confirmedUps.length,
      cancelled: aggregations.cancelledUps.length,
      delivered: aggregations.deliveredUps.length,
    };

    setState((state) => ({
      ...state,
      fedexStats: fedexStats,
      openFedex: aggregations.openFedex,
      errorFedex: aggregations.errorFedex,
      printedFedex: aggregations.printedFedex,
      warningFedex: aggregations.warningFedex,
      confirmedFedex: aggregations.confirmedFedex,
      cancelledFedex: aggregations.cancelledFedex,
      deliveredFedex: aggregations.deliveredFedex,
      upsStats: upsStats,
      openUps: aggregations.openUps,
      errorUps: aggregations.errorUps,
      printedUps: aggregations.printedUps,
      warningUps: aggregations.warningUps,
      confirmedUps: aggregations.confirmedUps,
      cancelledUps: aggregations.cancelledUps,
      deliveredUps: aggregations.deliveredUps,
    }));
  }, [dataProvider, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    userPermissions.loaded && fetchShipments();

    return () => {
      Promise.resolve(fetchShipments);
    };
  }, [dataProvider, selectedStartDate, selectedEndDate, userPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (state === undefined) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  const {
    fedexStats,
    openFedex,
    errorFedex,
    printedFedex,
    warningFedex,
    confirmedFedex,
    cancelledFedex,
    upsStats,
    openUps,
    errorUps,
    printedUps,
    warningUps,
    confirmedUps,
    cancelledUps,
  } = state;

  return (
    <div className={classes.dashboardWrapper}>
      <Welcome />
      {loading ? (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <div className={classes.dashboardLoaderWrapper}>
          <div className={classes.dashboardSection}>
            <Grid
              container
              wrap="wrap"
              spacing={2}
              key="dashboard-card-fedex-ups-list-section-1"
            >
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Error"
                  value={`${fedexStats.error}/${upsStats.error}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Warning"
                  value={`${fedexStats.warning}/${upsStats.warning}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Open"
                  value={`${fedexStats.open}/${upsStats.open}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Printed"
                  value={`${fedexStats.printed}/${upsStats.printed}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Confirmed"
                  value={`${fedexStats.confirmed}/${upsStats.confirmed}`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                <DashboardCard
                  status="Cancelled"
                  value={`${fedexStats.cancelled}/${upsStats.cancelled}`}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={4} zeroMinWidth>
            <DashboardCard
              status="Delivered"
              value={`${fedexStats.delivered}/${upsStats.delivered}`}
            />
          </Grid> */}
            </Grid>
          </div>
          <div className={classes.dashboardSection}>
            <FormControl
              variant="outlined"
              className={classes.datepicker}
              margin="normal"
            >
              <Select
                labelId="shipment-period-select-label"
                id="shipment-period-select"
                value={shipmentPeriod}
                onChange={hadleIntervalChange}
                // label="Shipment Period"
                inputProps={{ "aria-label": "Shipment Period" }}
              >
                <MenuItem value={0}>
                  <em>Custom</em>
                </MenuItem>
                <MenuItem value={10}>Today</MenuItem>
                <MenuItem value={20}>This Week</MenuItem>
                <MenuItem value={30}>This Month</MenuItem>
              </Select>
              <FormHelperText>Select Shipment Period</FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                id="stackedbar-startdate-picker-inline"
                label="Start Date"
                format="MM/dd/yyyy"
                margin="normal"
                className={classes.datepicker}
                value={selectedStartDate}
                onChange={(date: Date | null) => {
                  setShipmentPeriod(0);
                  date instanceof Date &&
                    !isNaN(date.getTime()) &&
                    !dateFns.isAfter(date, selectedEndDate) &&
                    setStartDate(date);
                }}
                disableToolbar
                maxDate={today}
                autoOk={true}
              />
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                id="stackedbar-enddate-picker-inline"
                label="End Date"
                format="MM/dd/yyyy"
                margin="normal"
                className={classes.datepicker}
                value={selectedEndDate}
                onChange={(date: Date | null) => {
                  setShipmentPeriod(0);
                  date instanceof Date &&
                    !isNaN(date.getTime()) &&
                    dateFns.isAfter(date, selectedStartDate) &&
                    setEndDate(date);
                }}
                disableToolbar
                maxDate={dateFns.addDays(today, 1)}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.dashboardSection}>
            <StatusStatsChart
              chartData={[
                {
                  name: "FedEx",
                  data: Object.values(fedexStats),
                },
                {
                  name: "UPS",
                  data: Object.values(upsStats),
                },
              ]}
            />
          </div>
          <div className={classes.dashboardSectionFedex}>
            <div className={classes.dashboardSection}>
              <Grid
                container
                wrap="wrap"
                key="dashboard-fedex-orders-list-section-1"
                spacing={2}
              >
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={errorFedex}
                    ship_via="FedEx"
                    status="Error"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={warningFedex}
                    ship_via="FedEx"
                    status="Warning"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={openFedex}
                    ship_via="FedEx"
                    status="Open"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={printedFedex}
                    ship_via="FedEx"
                    status="Printed"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={confirmedFedex}
                    ship_via="FedEx"
                    status="Confirmed"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={cancelledFedex}
                    ship_via="FedEx"
                    status="Cancelled"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.dashboardSectionUps}>
            <div className={classes.dashboardSection}>
              <Grid
                container
                wrap="wrap"
                key="dashboard-ups-orders-list"
                spacing={2}
              >
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={errorUps}
                    ship_via="UPS"
                    status="Error"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={warningUps}
                    ship_via="UPS"
                    status="Warning"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={openUps}
                    ship_via="UPS"
                    status="Open"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={printedUps}
                    ship_via="UPS"
                    status="Printed"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={confirmedUps}
                    ship_via="UPS"
                    status="Confirmed"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} zeroMinWidth>
                  <ShipmentsList
                    orders={cancelledUps}
                    ship_via="UPS"
                    status="Cancelled"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
