import React, { FC } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

// import backgroundImage from './welcome_bg.png';
// import publishArticleImage from './welcome_illustration.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.palette.type === "dark"
        ? "#535353"
        : `linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(253, 251, 251) 50%, rgb(156, 167, 172) 100%)`,
    border: "1px solid rgb(189, 189, 189)",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 1rem !important",
    color: "#515151",
    padding: "8px 12px 4px 8px",
    //marginTop: theme.spacing(0),
    marginBottom: "1em",
    overflow: "visible",
  },
  media: {
    background: `transparent none no-repeat scroll`,
    marginLeft: "auto",
    "& img": {
      height: "64px",
    },
  },
  actions: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexWrap: "wrap",
      "& a": {
        marginTop: "1em",
        marginLeft: "0!important",
        marginRight: "1em",
      },
    },
  },
}));

const Welcome: FC = () => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            {translate("pos.dashboard.welcome.title")}
          </Typography>
          <Box maxWidth="40em">
            <Typography variant="body1" component="p" gutterBottom>
              {translate("pos.dashboard.welcome.subtitle")}
            </Typography>
          </Box>
        </Box>
        <Box
          display={{ xs: "none", sm: "none", md: "block" }}
          width="auto"
          overflow="hidden"
        >
          <Box className={classes.media}>
            <img alt="Tayse | Ship Flow Portal" src="/tayse-light.png" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default Welcome;
