import React, { useEffect, useState, FC } from "react";
import { Title } from "react-admin";
import { fetchUtils } from "ra-core";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  IconButton,
  Menu,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { addParamsToEndpoint } from "../utils/request";
import { MoreVert } from "@material-ui/icons";
import UpdateStatusModal from "../components/UpdateStatusModal";
import CargoTrackingNumberField from "../orders/CargoTrackingNumberField";
import AcumaticaOrderField from "../orders/AcumaticaOrderField";
import AcumaticaShipmentField from "../orders/AcumaticaShipmentField";

const httpClient = fetchUtils.fetchJson;

const initialParams = {
  shipment_status: "Partial Movement",
  cargo_tracking_number: "",
  ship_via: "",
  shipment_id: "",
  _end: 1000,
};

interface ShipmentProps {
  id: string;
  shipment_id: string;
  ship_via: string;
  cargo_tracking_number: string;
  order_id: string;
  customer_id: string;
}

interface ActionsProps {
  shipment_id: string;
  setStatus: any;
  setOpen: any;
  setShipmentIds: any;
}

const ITEM_HEIGHT = 48;

const options = ["Cancelled", "Delivered", "In Transit"];

const Actions: FC<ActionsProps> = ({
  shipment_id,
  setStatus,
  setOpen,
  setShipmentIds,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (event: any) => {
    setStatus(event.target.innerText);
    setOpen(true);
    setShipmentIds(shipment_id);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleItemClick}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const PartialMovements = () => {
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState<Boolean>(true);
  const [items, setItems] = useState<Array<ShipmentProps>>([]);
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [shipmentIds, setShipmentIds] = useState("");
  const [isDone, setIsDone] = useState(false);

  const getPartialMovements = (params: {}) => {
    let options = {
      headers: new Headers({}),
    };
    const email = localStorage.getItem("email");
    options.headers.set("X-Email", email ? email : "");
    httpClient(
      addParamsToEndpoint(
        `${process.env.REACT_APP_HEROKU_API_URL}/order`,
        params
      ),
      options
    )
      .then((res) => {
        if (res.status === 200) {
          setItems(res.json);
        }
      })
      .catch(() => {
        setItems([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeFilterInput = (prop: string) => (e: any) => {
    setParams({ ...params, [prop]: e.target.value ? e.target.value : "" });
    setLoading(true);
  };

  const handleChangeTextInput = (prop: string) => (e: any) => {
    setParams({ ...params, [prop]: e.target.value ? e.target.value : "" });
  };

  useEffect(() => {
    if (loading || isDone) {
      getPartialMovements(params);
    }
  }, [params, loading, isDone]);

  return (
    <Card>
      <Title title="Partial Movements" />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Shipment Number"
              value={params.shipment_id}
              onChange={handleChangeTextInput("shipment_id")}
              onKeyPress={({ key }) =>
                key === "Enter" ? setLoading(true) : null
              }
            />
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Tracking Number"
              value={params.cargo_tracking_number}
              onChange={handleChangeTextInput("cargo_tracking_number")}
              onKeyPress={({ key }) =>
                key === "Enter" ? setLoading(true) : null
              }
            />
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Carrier</InputLabel>
              <Select
                value={params.ship_via}
                label="Carrier"
                onChange={handleChangeFilterInput("ship_via")}
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"FedEx"}>Fedex</MenuItem>
                <MenuItem value={"UPS"}>UPS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <UpdateStatusModal
              status={status}
              shipmentIds={shipmentIds}
              setShipmentIds={setShipmentIds}
              open={open}
              setOpen={setOpen}
              setIsDone={setIsDone}
            />
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Customer Id</TableCell>
                    <TableCell>Shipment Nbr.</TableCell>
                    <TableCell>Ship Via</TableCell>
                    <TableCell>Tracking Number</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <LinearProgress color="secondary" />
                      </TableCell>
                    </TableRow>
                  ) : !items.length ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Alert color={"info"}>No Records Found.</Alert>
                      </TableCell>
                    </TableRow>
                  ) : (
                    items.map((row) => (
                      <TableRow key={row.shipment_id}>
                        <TableCell>
                          <AcumaticaOrderField record={row} />
                        </TableCell>
                        <TableCell>{row.customer_id}</TableCell>
                        <TableCell>
                          <AcumaticaShipmentField record={row} />
                        </TableCell>
                        <TableCell>{row.ship_via}</TableCell>
                        <TableCell>
                          <CargoTrackingNumberField
                            ship_via={row.ship_via}
                            tracking_number={row.cargo_tracking_number}
                          />
                        </TableCell>
                        <TableCell>
                          <Actions
                            shipment_id={row.shipment_id}
                            setStatus={setStatus}
                            setOpen={setOpen}
                            setShipmentIds={setShipmentIds}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PartialMovements;
