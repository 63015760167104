import { TranslationMessages } from "ra-core";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      shipment_cost: "Shipment Cost",
      shipping_companies: "FedEx/UPS",
      shipping_fedex: "FedEx",
      shipping_ups: "UPS",
      open_fedex: "FedEx (Open)",
      confirmed_fedex: "FedEx (Confirmed)",
      cancelled_fedex: "FedEx (Cancelled)",
      delivered_fedex: "FedEx (Delivered)",
      none_fedex: "FedEx (???)",
      open_ups: "UPS (Open)",
      confirmed_ups: "UPS (Confirmed)",
      cancelled_ups: "UPS (Cancelled)",
      delivered_ups: "UPS (Delivered)",
      none_ups: "UPS (???)",
      monthly_revenue: "Monthly Revenue",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      new_customers: "New Customers",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "Welcome",
        subtitle: "Tayse International Trading Batch System",
        aor_button: "react-admin site",
        demo_button: "Source for this demo",
      },
    },
    menu: {
      box: "Boxes",
      order: "Shipments",
      partial_movements: "Partial Movements",
    },
    submenu: {
      rugs: "Rugs",
      furniture: "Furniture",
    },
  },
  resources: {
    order: {
      name: "Shipment |||| Shipments",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      detail: "Order detail",
      fields: {
        primary: {
          order_id: "Order Id",
          customer_id: "Customer Id",
          shipment_id: "Shipment Nbr.",
          ship_via: "Ship Via",
          customer_order_number: "Customer Order Number",
          cargo_tracking_number: "Cargo Tracking Number",
        },
        shipment: {
          shipment_id: "Shipment Nbr.",
          ship_via: "Ship Via",
          cargo_tracking_number: "Tracking Number",
          shipment_cost: "Shipment Cost",
          use_weight: "Use Weight",
          is_cancelled: "Is Cancelled",
          minimum_insurance: "Minimum Insurance",
          address_line1: "Address Line#1",
          address_line2: "Address Line#2",
          address_line3: "Address Line#3",
          city: "City",
          state: "State",
          country: "Country",
          postal_code: "Postal Code",
        },
        items: {
          item_quantity: "Item Number",
          total_quantity: "Total Quantity",
        },
        packages: {
          id: "Id",
          shipment_id: "Shipment Nbr.",
          package_acumatica_id: "Package Acumatica Id",
          item_id: "Item Id",
          tracking_number: "Tracking Nmbr(s).",
          location: "Location",
          label: "Label",
          weight: "Weight",
          dims: "Dims (L x W x H)",
          length: "Length",
          width: "Width",
          height: "Height",
          item_description: "Item Description",
          item_class: "Class",
          item_type: "Type",
          item_status: "Item Status",
          empty: "No Package Record!",
        },
        customer: {
          customer_order_number: "Customer Order Number",
          customer_reference_number: "Customer Reference Number",
          contact_attention: "Contact Aattention",
          contact_email: "Contact Email",
          contact_phone: "Contact Phone",
          contact_company_name: "Contact Company Name",
        },
        fedex: {
          fedex_customer_reference: "FedEx Customer Reference",
          fedex_department_note: "FedEx Department Note",
          fedex_invoice_number: "FedEx Invoice Number",
          fedex_account_id: "FedEx Account Id",
          fedex_po_number: "FedEx PO Number",
        },
        ups: {
          ups_account_id: "UPS Account Id",
          ups_reference1: "UPS Reference#1",
          ups_reference2: "UPS Reference#2",
          ups_reference3: "UPS Reference#3",
          ups_reference4: "UPS Reference#4",
          ups_reference5: "UPS Reference#5",
        },
        order_id: "Order Id",
        customer_id: "Customer Id",
        shipment_id: "Shipment Nbr.",
        ship_via: "Ship Via",
        status: "Status",
        created_at: "Created At",
        cancelled_at: "Cancelled At",
        sent_at: "Sent At",
        is_cancelled: "Is Cancelled",
        shipment_type: "Shipment Type",
        shipment_date: "Shipment Date",
        error_source: "Error Source",
        error_message: "Error Message",
        shipment_cost: "Shipment Cost",
        shipment_address: "Shipment Address",
        address_line1: "Address Line#1",
        address_line2: "Address Line#2",
        address_line3: "Address Line#3",
        city: "City",
        state: "State",
        country: "Country",
        postal_code: "Postal Code",
        item_quantity: "Item Number",
        total_quantity: "Total Quantity",
        empty: "Empty Package",
        contact_attention: "Contact Attention",
        contact_email: "Contact Email",
        contact_phone: "Contact Phone",
        contact_company_name: "Contact Company Name",
        fedex_customer_reference: "FedEx Customer Reference",
        fedex_department_note: "FedEx Department Note",
        fedex_invoice_number: "FedEx Invoice Number",
        fedex_account_id: "FedEx Account Id",
        fedex_po_number: "FedEx PO Number",
        use_weight: "Use Weight",
        minimum_insurance: "Minimum Insurance",
        ups_account_id: "UPS Account Id",
        ups_reference1: "UPS Reference#1",
        ups_reference2: "UPS Reference#2",
        ups_reference3: "UPS Reference#3",
        ups_reference4: "UPS Reference#4",
        ups_reference5: "UPS Reference#5",
        cargo_tracking_number: "Tracking Number",
        customer_reference_number: "Customer Reference Number",
        customer_order_number: "Customer Order Number",
      },
      tabs: {},
      filters: {},
      actions: {
        print: {
          success: "Your request has been queued.",
          error: "Error : %{reason}",
          process: "Processing your request...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
        refresh: {
          success: "Order details updated.",
          error: "Error : %{reason}",
          process: "Updating order details...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
        correct: {
          success: "Order details updated.",
          error: "Error : %{reason}",
          process: "Updating order details...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
        cancel: {
          success: "Shipment cancelled.",
          error: "Error : %{reason}",
          process: "Updating order details...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
        update: {
          success: "Shipment updated.",
          error: "Error : %{reason}",
          process: "Updating shipment details...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
        delete: {
          success: "Shipment deleted.",
          error: "Error : %{reason}",
          process: "Updating order details...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
      },
    },
    box: {
      name: "Box |||| Boxes",
      amount: "1 box |||| %{smart_count} boxes",
      title: "Box %{reference}",
      fields: {
        id: "Box Id",
        length: "Length",
        width: "Width",
        height: "Height",
      },
      actions: {
        refresh: {
          success: "Import Boxes: Success.",
          error: "Error : %{reason}",
          process: "Importing Boxes...",
          unknown: "Something went wrong!",
          finished: "Done",
        },
      },
    },
    customer: {
      name: "Customer |||| Customers",
      amount: "1 Customer |||| %{smart_count} Customers",
      title: "Customers %{reference}",
      fields: {
        id: "Identity",
        acumatica_id: "Acumatica Id",
        name: "Name",
        address_line1: "Address Line1",
        address_line2: "Address Line2",
        address_line3: "Address Line3",
        city: "City",
        state: "State",
        country: "Country",
        postal_code: "Postal Code",
        fedex_account_id: "Fedex Account Id",
        fedex_customer_reference: "Fedex Customer Reference",
        department_note: "Department Note",
        po_number: "PO Number",
        invoice_number: "Invoice Number",
        minimum_insurance: "Minimum Insurance",
        ups_account_id: "UPS Account Id",
        reference1: "Reference1",
        reference2: "Reference2",
        reference3: "Reference3",
        reference4: "Reference4",
        reference5: "Reference5",
        use_weight: "Use Weight",
      },
      actions: {},
    },
    end_of_day: {
      report: "End of Day Report",
      download_title: "Download End of Day Report",
      date: "Close Date",
      error: "End of day report not exist for given date.",
    },
    morning_batch: {
      print: "Morning Batch Print",
      download_title: "Download Morning Batch Print",
      date: "Print Date",
      error: "Couldn't get morning batch file.",
    },
  },
};

customEnglishMessages["ra"]["action"]["export"] = "CSV";
customEnglishMessages["ra"]["action"]["download"] = "Download";

export default customEnglishMessages;
