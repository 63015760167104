import { blueGrey, red } from "@material-ui/core/colors";
import { Theme } from "@material-ui/core";
import { Order } from "./../types";
import { Identifier } from "ra-core";

const rowStyle = (selectedRow: Identifier, theme: Theme) => (record: Order) => {
  let style = {};

  if (selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor:
        record.cargo_tracking_number !== null
          ? "rgba(67, 149, 255, 0.13)"
          : red[50],
    };
  }

  if (record.cargo_tracking_number !== null)
    return {
      ...style,
      borderLeftColor: blueGrey["A400"],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
  else
    return {
      ...style,
      borderLeftColor: red["A200"],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    };
};

export default rowStyle;
