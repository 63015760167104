import React, { FC } from "react";
import { useTranslate } from "react-admin";
import CardWithIcon from "./CardWithIcon";

interface Props {
  value?: string;
  status?: string;
}

const DashboardCard: FC<Props> = ({ value, status }) => {
  const translate = useTranslate();

  return (
    <CardWithIcon
      to={encodeURI(
        `/order/?filter=${JSON.stringify({ shipment_status: status })}`
      )}
      status={status}
      title={translate("pos.dashboard.shipping_companies")}
      subtitle={value ? value : "0 / 0"}
    />
  );
};
export default DashboardCard;
