import React, { FC } from "react";
import { Datagrid, TextField } from "react-admin";
import grey from "@material-ui/core/colors/grey";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import AcumaticaShipmentField from "./AcumaticaShipmentField";
import rowStyle from "./rowStyle";

import { DatagridProps } from "./../types";
import { Identifier } from "ra-core";
import AcumaticaOrderField from "./AcumaticaOrderField";
import ShippingCargoField from "./ShippingCargoField";
import CargoTrackingNumberField from "./CargoTrackingNumberField";
import ShipmentDateField from "./ShipmentDateField";

const useListStyles = makeStyles((theme) => ({
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 0,
    borderLeftStyle: "solid",
    borderBottomColor: grey[500],
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  headerCell: {
    padding: "8px",
    backgroundColor: grey[200],
  },
  rowCell: {
    padding: "8px",
    verticalAlign: "middle",
  },
  comment: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  avatarFlex: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "start",
  },
}));

export interface OrderListDesktopProps extends DatagridProps {
  selectedRow?: Identifier;
}

const OrderListDesktop: FC<OrderListDesktopProps> = ({
  selectedRow,
  ...props
}) => {
  const classes = useListStyles();
  const theme = useTheme();
  const postRowClick = (
    id: Identifier,
    basePath: string,
    record: Record<any, any>
  ) => {
    // return field.record && field.record.editable ? "edit" : "show";
    // Fields are not editable. So, we always return "show"
    return "show";
  };

  return (
    <Datagrid
      rowClick={postRowClick}
      // @ts-ignore
      rowStyle={selectedRow ? rowStyle(selectedRow, theme) : undefined}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <AcumaticaOrderField />
      <TextField source="customer_id" />
      <AcumaticaShipmentField />
      <ShipmentDateField />
      <ShippingCargoField className={classes.avatarFlex} />
      <TextField source="customer_order_number" />
      <CargoTrackingNumberField />
    </Datagrid>
  );
};

export default OrderListDesktop;
