import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

import { FieldProps, Order } from "../types";

const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    opacity: 0.87,
    whiteSpace: "nowrap",
    fontSize: "0.925rem",
    color: "#29007c",
    lineHeight: 1.5,
    marginLeft: "-5px",
    marginRight: "-5px",
    "&:hover,&:focus": {
      opacity: 1,
    },
    "&:focus": {
      backgroundColor: "rgba(209, 196, 233, 0.55)",
    },
  },
  spanned: {},
});

const getCargoTrackingLink = (cargo: string) => {
  if (cargo.match(/^FEDEX/))
    return "https://www.fedex.com/apps/fedextrack/index.html?action=track&tracknumbers={number}";
  if (cargo.match(/^UPS/))
    return "https://www.ups.com/track?loc=en_US&tracknum={number}";
  return "#";
};

interface OwnProps extends FieldProps<Order> {
  ship_via?: string | null;
  tracking_number?: string | number | null;
}

const CargoTrackingNumberField: FC<OwnProps> = (props) => {
  const classes = useStyles();
  const hrefAddress = getCargoTrackingLink(
    props.record
      ? props.record.ship_via
        ? props.record.ship_via
        : ""
      : props.ship_via
      ? props.ship_via
      : ""
  );
  const trackingNumber = props.record
    ? props.record.cargo_tracking_number
      ? (props.record.cargo_tracking_number as number)
      : null
    : props.tracking_number
    ? (props.tracking_number as number)
    : null;

  return trackingNumber ? (
    <a
      href={hrefAddress.replace(/{number}$/gim, String(trackingNumber))}
      className={classes.root}
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {trackingNumber}
      <LaunchIcon fontSize="small" />
    </a>
  ) : null;
};

CargoTrackingNumberField.defaultProps = {
  label: "resources.order.fields.cargo_tracking_number",
  source: "cargo_tracking_number",
  addLabel: true,
};

export default CargoTrackingNumberField;
