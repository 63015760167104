const addParamsToEndpoint = (endpoint: string, params: any) => {
  if (!params || !Object.keys(params).length) {
    return endpoint;
  }
  let parameters = Object.keys(params)
    .map((param) =>
      params[param] || typeof params[param] === "boolean"
        ? `${param}=${params[param]}`
        : null
    )
    .filter(Boolean)
    .join("&");
  return `${endpoint}?${parameters}`;
};

export { addParamsToEndpoint };
