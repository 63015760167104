import React from "react";
import { useTranslate } from "react-admin";
import { fetchUtils } from "ra-core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  noGutter: {
    padding: 0,
  },
  dialogTitle: { padding: "0.25rem 1rem", backgroundColor: blueGrey[100] },
  objectPdf: { width: "100%", minHeight: "800px" },
  downloadWrapper: {
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flex: "1 0 0",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface PrintInvoiceDialogProps extends DialogProps {
  shipmentId?: string | number;
  onClose?: (event: React.SyntheticEvent, reason?: string) => void;
}

const httpClient = fetchUtils.fetchJson;

const PrintInvoiceModal: React.FC<PrintInvoiceDialogProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [loading, setLoading] = React.useState<Boolean>(true);
  const [pdfData, setPdfData] = React.useState<string | null>();

  const handleDialog = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    if (!props.shipmentId) {
      return;
    }
    setLoading(true);
    httpClient(
      `${process.env.REACT_APP_HEROKU_API_URL}/shipment/commercial-invoice/${props.shipmentId}`
    )
      .then((response) => {
        response.json && setPdfData(response.json.data);
      })
      .finally(() => setLoading(false));

    return () => {
      setPdfData(null);
    };
  }, [props.shipmentId]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      open={props.open}
      onEntered={() => {}}
      onExited={handleDialog}
      keepMounted
      onBackdropClick={props.onClose}
      aria-labelledby="item-invoice-dialog-title"
    >
      <DialogTitle
        id="item-invoice-dialog-title"
        className={classes.dialogTitle}
        color="primary"
        key={`dialog-title${props.shipmentId}`}
      >
        {`${translate(`resources.order.fields.shipment_id`)} ${
          props.shipmentId
        } [COMMERCIAL INVOICE - PDF]`}
      </DialogTitle>
      <DialogContent dividers={true} className={classes.noGutter}>
        <Paper square variant="elevation">
          {loading ? (
            <LinearProgress color="secondary" />
          ) : !pdfData ? (
            <Alert severity="error">Label Source Error!</Alert>
          ) : (
            <object
              data={pdfData}
              type="application/pdf"
              className={classes.objectPdf}
              role="document"
              aria-live="assertive"
              aria-label={`pdf-invoice-${props.shipmentId}`}
            ></object>
          )}
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogTitle} role="close">
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          size="small"
          endIcon={<CloseIcon fontSize="small" />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintInvoiceModal;
