import React, { FC } from "react";
import {
  DateInput,
  Filter,
  SearchInput,
  SelectInput,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { FilterProps } from "../types";
import { Identifier } from "ra-core";

const useFilterStyles = makeStyles({
  order_id: { width: 160 },
  customer_class: { width: 160 },
  customer_id: { width: 160 },
  ship_via: { width: 120, minWidth: "auto" },
  customer_order_number: { width: 220 },
  shipment_id: { width: 160 },
  city: { width: 120 },
  state: { width: 120 },
  country: { width: 120 },
  postal_code: { width: 140 },
});

export type OrderStatus =
  | "Open"
  | "Pickup Requested"
  | "Printed"
  | "Delivered"
  | "Cancelled";

interface FilterParams {
  order_id?: Identifier;
  customer_class?: Identifier;
  customer_id?: Identifier;
  ship_via?: Identifier;
  shipment_id?: Identifier;
  customer_order_number?: Identifier;
  customer_reference_number?: Identifier;
  city?: Identifier;
  state?: Identifier;
  country?: Identifier;
  postal_code?: Identifier;
  contact_attention?: Identifier;
  contact_email?: Identifier;
  contact_company_name?: Identifier;
  cargo_tracking_number?: Identifier;
  status?: OrderStatus;
}

const OrderFilter: FC<FilterProps<FilterParams>> = (props) => {
  const translate = useTranslate();
  const classes = useFilterStyles();

  return (
    <Filter {...props}>
      <SelectInput
        label="Batch Type"
        source="shipment_type"
        alwaysOn
        choices={[
          { id: "Re-Roll", name: "Re-Roll" },
          { id: "Furniture", name: "Furniture" },
          { id: "Rugs", name: "Rugs" },
          { id: "SinglePiece", name: "SinglePiece" },
          { id: "MultiPiece", name: "MultiPiece" },
        ]}
        className={classes.ship_via}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.order_id")}
        source="order_id"
        alwaysOn
        className={classes.order_id}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.customer_id")}
        source="customer_id"
        alwaysOn
        className={classes.customer_id}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.shipment_id")}
        source="shipment_id"
        alwaysOn
        className={classes.shipment_id}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.customer_order_number")}
        source="customer_order_number"
        alwaysOn
        className={classes.customer_order_number}
      />
      <DateInput label="Start Date" source="start_date" alwaysOn />
      <DateInput label="End Date" source="end_date" alwaysOn />
      <SelectInput
        label="Customer Class"
        source="customer_class"
        choices={[
          { id: "Ecommerce", name: "Ecommerce" },
          { id: "Retail", name: "Retail" },
        ]}
        className={classes.customer_class}
      />
      <SearchInput
        placeholder={translate(
          "resources.order.fields.customer_reference_number"
        )}
        source="customer_reference_number"
      />
      <SearchInput
        placeholder={translate("resources.order.fields.city")}
        source="city"
        className={classes.city}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.state")}
        source="state"
        className={classes.state}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.country")}
        source="country"
        className={classes.country}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.postal_code")}
        source="postal_code"
        className={classes.postal_code}
      />
      <SearchInput
        placeholder={translate("resources.order.fields.contact_attention")}
        source="contact_attention"
      />
      <SearchInput
        placeholder={translate("resources.order.fields.contact_email")}
        source="contact_email"
      />
      <SearchInput
        placeholder={translate("resources.order.fields.contact_company_name")}
        source="contact_company_name"
      />
      <SearchInput
        placeholder={translate("resources.order.fields.cargo_tracking_number")}
        source="cargo_tracking_number"
      />
    </Filter>
  );
};

export default OrderFilter;
