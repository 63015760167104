import React, { FC } from "react";
import {
  useDataProvider,
  useShowController,
  useTranslate,
  useNotify,
  useRefresh,
} from "react-admin";
import { DataProvider, GetOneResult, fetchUtils } from "ra-core";
import {
  Divider,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  LinearProgress,
  Tooltip,
  Grid,
  Paper,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import {
  deepOrange,
  deepPurple,
  blueGrey,
  red,
  grey,
} from "@material-ui/core/colors";

import {
  Close as CloseIcon,
  Details as DetailsIcon,
  Print as PrintIcon,
  VisibilityOffRounded as HideIcon,
  Receipt as ReceiptIcon,
} from "@material-ui/icons";

import { Order, ShowComponentProps } from "../types";
import {
  ShipmentAccordion,
  CustomerAccordion,
  ItemsAccordion,
} from "../components";
import CargoAvatarField from "../orders/CargoAvatarField";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
  },
  title: {
    color: theme.palette.getContrastText(blueGrey[900]),
    backgroundColor: blueGrey[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1em 0",
  },
  detailFrame: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
    "& .MuiListItem-dense.MuiListItem-gutters": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  featuresGrid: {
    alignItems: "center",
    // backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    "& *": {
      letterSpacing: "0.01071em",
    },
    padding: "6px 8px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  accordionSummary: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionDetails: {
    flexDirection: "column",
  },
  paper: {
    // color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[100],
    width: "auto",
    margin: "0",
    borderRadius: "0",
  },
  avatarFlex: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "auto",
  },
  arrowRight: {
    color: "crimson",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  secondaryActButtonWrapper: {
    margin: `2px ${theme.spacing(1)}px`,
    position: "relative",
    display: "inline-flex",
    height: "100%",
    width: "auto",
  },
  buttonProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  secondaryActRenewButton: {
    marginLeft: theme.spacing(1),
    color: red[500],
    backgroundColor: grey[300],
    "&:hover,&:active,&:focus": {
      color: red[300],
      backgroundColor: grey[100],
    },
  },
  secondaryActPrintButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepPurple[800]),
      backgroundColor: deepPurple[800],
    },
  },
  secondaryActHideButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    "&:hover,&:active,&:focus": {
      color: theme.palette.getContrastText(deepOrange[800]),
      backgroundColor: deepOrange[800],
    },
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  purpleInvert: {
    color: deepPurple[500],
    backgroundColor: "transparent",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  gridRoot: {
    flexGrow: 1,
  },
}));

interface Props extends ShowComponentProps {
  onCancel: () => void;
  onPrint: (modalOpen: boolean, shipment: string, pdfSource?: string) => void;
  onInvoicePrint: (modalOpen: boolean, shipment_id: string | number) => void;
  onHide: (modalOpen: boolean, shipment_id: string) => void;
}

const httpClient = fetchUtils.fetchJson;

const TEMP_REPRINT_ALLOWED_EMAILS = [
  "emin.yildirim@tayse.com",
  "yigit.altintas@tayse.com",
  "brandi.davis@tayse.com",
  "zan@tayse.com",
  "joseph@tayse.com",
  "lesley@tayse.com",
];

const OrderShow: FC<Props> = ({
  onCancel,
  onPrint,
  onInvoicePrint,
  onHide,
  ...props
}) => {
  const dataProvider: DataProvider = useDataProvider();

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const controllerProps = useShowController(props);
  const [orderRecord, setOrderRecord] = React.useState<Order>(
    controllerProps.record as Order
  );
  const [autoRenewAction, setAutoRenewAction] = React.useState({
    loading: false,
    disabled: false,
  });

  // Request headers for custom backend requests
  let options = {
    headers: new Headers({}),
  };
  const [email] = React.useState(localStorage.getItem("email") || "");
  options.headers.set("X-Email", email ? email : "");

  React.useEffect(() => {
    if (controllerProps.record !== undefined && controllerProps.loaded) {
      setOrderRecord(controllerProps.record);
    }
    setAutoRenewAction({
      loading: controllerProps.loading,
      disabled: controllerProps.loading,
    });
    return () => {};
  }, [controllerProps.loaded, controllerProps.record, controllerProps.loading]);

  const autoCorrectShipment = (shipment_ids: string) => {
    if (!autoRenewAction.loading) {
      setAutoRenewAction({ loading: true, disabled: true });
      notify(
        "resources.order.actions.correct.process",
        "success",
        {},
        false,
        2000
      );

      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/update?ids=${shipment_ids}`,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.correct.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.correct.error",
                "error",
                { reason: response.json.message },
                false,
                2000
              );
              break;
            default:
              notify(
                "resources.order.actions.correct.unknown",
                "warning",
                {},
                false,
                2000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.correct.error",
            "error",
            { reason: reason },
            false,
            2000
          );
        })
        .finally(() => {
          dataProvider
            .getOne("order", {
              id: props.id,
            })
            .then((result: GetOneResult) => {
              setOrderRecord(result.data as Order);
              refresh();
            })
            .catch((error: PromiseRejectedResult) => {
              notify(
                "resources.order.actions.correct.error",
                "error",
                { reason: `${error.status}: ${error.reason}` },
                false,
                2000
              );
            })
            .finally(() => {
              notify(
                "resources.order.actions.correct.finished",
                "info",
                {},
                false,
                2000
              );
              setAutoRenewAction({ loading: false, disabled: false });
            });
        });
    }
  };

  const autoRenewShipment = (ship_via: Order["ship_via"]) => {
    if (orderRecord.packages === undefined) {
      notify(
        "resources.order.actions.refresh.error",
        "warning",
        { reason: `Please Refresh Package Items First.` },
        false,
        5000
      );
      return;
    }

    let ship_via_select = ship_via?.match(/\s?(FEDEX|UPS)\s+/gi);

    if (ship_via_select === null || ship_via_select === undefined) {
      notify(
        "resources.order.actions.refresh.error",
        "error",
        { reason: `Incomplete Order Details: Ship Via.` },
        false,
        2000
      );
      return;
    }

    if (!autoRenewAction.loading) {
      setAutoRenewAction({ loading: true, disabled: true });
      notify(
        "resources.order.actions.refresh.process",
        "success",
        {},
        false,
        2000
      );
      // setOrderRecord({ ...orderRecord, packages: [] });
      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/${String(ship_via_select[0])
          .trim()
          .toLocaleLowerCase()}/ship/` + orderRecord.order_id,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.refresh.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.refresh.error",
                "error",
                { reason: response.json.message },
                false,
                2000
              );
              break;
            default:
              notify(
                "resources.order.actions.refresh.unknown",
                "warning",
                {},
                false,
                2000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.refresh.error",
            "error",
            { reason: reason },
            false,
            2000
          );
        })
        .finally(() => {
          dataProvider
            .getOne("order", {
              id: props.id,
            })
            .then((result: GetOneResult) => {
              setOrderRecord(result.data as Order);
              refresh();
            })
            .catch((error: PromiseRejectedResult) => {
              notify(
                "resources.order.actions.refresh.error",
                "error",
                { reason: `${error.status}: ${error.reason}` },
                false,
                2000
              );
            })
            .finally(() => {
              notify(
                "resources.order.actions.refresh.finished",
                "info",
                {},
                false,
                2000
              );
            });
          setAutoRenewAction({ loading: false, disabled: false });
        });
    }
  };

  const autoCancelShipment = () => {
    if (!autoRenewAction.loading) {
      setAutoRenewAction({ loading: true, disabled: true });
      notify(
        "resources.order.actions.cancel.process",
        "success",
        {},
        false,
        2000
      );

      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/cancel/` +
          orderRecord.shipment_id,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.cancel.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.cancel.error",
                "error",
                { reason: response.json.message },
                false,
                2000
              );
              break;
            default:
              notify(
                "resources.order.actions.cancel.unknown",
                "warning",
                {},
                false,
                2000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.cancel.error",
            "error",
            { reason: reason },
            false,
            2000
          );
        })
        .finally(() => {
          dataProvider
            .getOne("order", {
              id: props.id,
            })
            .then((result: GetOneResult) => {
              setOrderRecord(result.data as Order);
              refresh();
            })
            .catch((error: PromiseRejectedResult) => {
              notify(
                "resources.order.actions.cancel.error",
                "error",
                { reason: `${error.status}: ${error.reason}` },
                false,
                2000
              );
            })
            .finally(() => {
              notify(
                "resources.order.actions.cancel.finished",
                "info",
                {},
                false,
                2000
              );
              setAutoRenewAction({ loading: false, disabled: false });
            });
        });
    }
  };

  const moveShipmentCancelled = () => {
    if (!autoRenewAction.loading) {
      setAutoRenewAction({ loading: true, disabled: true });

      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/move/cancel/` +
          orderRecord.shipment_id,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.cancel.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.cancel.error",
                "error",
                { reason: response.json.message },
                false,
                2000
              );
              break;
            default:
              notify(
                "resources.order.actions.cancel.unknown",
                "warning",
                {},
                false,
                2000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.cancel.error",
            "error",
            { reason: reason },
            false,
            2000
          );
        })
        .finally(() => {
          dataProvider
            .getOne("order", {
              id: props.id,
            })
            .then((result: GetOneResult) => {
              setOrderRecord(result.data as Order);
              refresh();
            })
            .catch((error: PromiseRejectedResult) => {
              notify(
                "resources.order.actions.cancel.error",
                "error",
                { reason: `${error.status}: ${error.reason}` },
                false,
                2000
              );
            })
            .finally(() => {
              notify(
                "resources.order.actions.cancel.finished",
                "info",
                {},
                false,
                2000
              );
              setAutoRenewAction({ loading: false, disabled: false });
            });
        });
    }
  };

  const deleteShipment = () => {
    if (!autoRenewAction.loading) {
      setAutoRenewAction({ loading: true, disabled: true });

      httpClient(
        `${process.env.REACT_APP_HEROKU_API_URL}/shipment/delete/` +
          orderRecord.shipment_id,
        options
      )
        .then((response) => {
          switch (response.json.status) {
            case "success":
              notify(
                "resources.order.actions.delete.success",
                "success",
                {},
                false,
                2000
              );
              break;
            case "error":
              notify(
                "resources.order.actions.delete.error",
                "error",
                { reason: response.json.message },
                false,
                2000
              );
              break;
            default:
              notify(
                "resources.order.actions.delete.unknown",
                "warning",
                {},
                false,
                2000
              );
              break;
          }
        })
        .catch((reason) => {
          notify(
            "resources.order.actions.delete.error",
            "error",
            { reason: reason },
            false,
            2000
          );
        })
        .finally(() => {
          setAutoRenewAction({ loading: false, disabled: false });
          onCancel();
          refresh();
        });
    }
  };

  if (controllerProps.record === undefined) {
    return null;
  }

  return orderRecord !== undefined ? (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.order.detail")}
        </Typography>
        <IconButton disableRipple={true} onClick={onCancel}>
          <CloseIcon color="inherit" className={classes.orange} />
        </IconButton>
      </div>
      <div className={classes.detailFrame}>
        <List key={`detailFrameList-${props.id}`}>
          <ListItem key={`detailFrameListItem-${props.id}`}>
            <ListItemAvatar key="detailFrameAvatar">
              <Avatar className={classes.purple}>
                <DetailsIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={orderRecord.order_id}
              secondary={orderRecord.customer_id}
            />
            {[
              "Open",
              "Pickup Requested",
              "Error",
              "Printed",
              "Warning",
              "In Transit",
              "Delivered",
              "Cancelled",
            ].indexOf(orderRecord.status as string) >= 0 ? (
              <ListItemSecondaryAction>
                {orderRecord.status === "Warning" ? (
                  <div className={classes.secondaryActButtonWrapper}>
                    <Tooltip
                      title="Hide Shipment"
                      aria-label="hide-shipment-button"
                    >
                      <IconButton
                        className={classes.secondaryActHideButton}
                        aria-label="hide-shipment"
                        onClick={() => {
                          onHide(
                            true,
                            orderRecord.shipment_id
                              ? (orderRecord.shipment_id as string)
                              : ""
                          );
                        }}
                      >
                        <HideIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
                {orderRecord.country !== "US" && (
                  <div className={classes.secondaryActButtonWrapper}>
                    <Tooltip
                      title="Print Commercial Invoice"
                      aria-label="print-invoice-button"
                    >
                      <IconButton
                        className={classes.secondaryActPrintButton}
                        aria-label="print-invoice"
                        onClick={() =>
                          onInvoicePrint(true, orderRecord.shipment_id || "")
                        }
                      >
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!["Error", "In Transit", "Delivered", "Cancelled"].includes(
                  orderRecord.status || ""
                ) && (
                  <div className={classes.secondaryActButtonWrapper}>
                    <Tooltip
                      title="Print Labels"
                      aria-label="print-labels-button"
                    >
                      <IconButton
                        className={classes.secondaryActPrintButton}
                        aria-label="print-label"
                        onClick={() => {
                          onPrint(
                            true,
                            orderRecord.shipment_id
                              ? (orderRecord.shipment_id as string)
                              : ""
                          );
                        }}
                      >
                        <PrintIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {["In Transit", "Delivered", "Cancelled"].includes(
                  orderRecord.status || ""
                ) &&
                  TEMP_REPRINT_ALLOWED_EMAILS.includes(email) && (
                    <div className={classes.secondaryActButtonWrapper}>
                      <Tooltip
                        title="Re-print Labels"
                        aria-label="print-labels-button"
                      >
                        <IconButton
                          className={classes.secondaryActPrintButton}
                          aria-label="reprint-label"
                          onClick={() => {
                            fetch(
                              `${process.env.REACT_APP_HEROKU_API_URL}/shipment/reprint?shipments=${orderRecord.shipment_id}`,
                              {
                                method: "GET",
                              }
                            )
                              .then(async (response) => {
                                if (response.status === 200) {
                                  return response.blob();
                                } else {
                                  throw await response.json();
                                }
                              })
                              .then((blob) => {
                                var url = window.URL.createObjectURL(blob);
                                var a = document.createElement("a");
                                a.href = url;
                                a.download = `${orderRecord.shipment_id}.pdf`;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                              })
                              .catch((error) => {
                                notify(
                                  "resources.order.actions.print.error",
                                  "error",
                                  {
                                    reason:
                                      error?.message || "Unexpected error!",
                                  },
                                  false,
                                  2000
                                );
                              });
                          }}
                        >
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        </List>
        <Divider variant="middle" />
        <div>
          {orderRecord.status === "Error" ? (
            <Paper className={classes.paper}>
              <Grid
                className={classes.featuresGrid}
                container
                wrap="wrap"
                key="shipment-row-error_source"
              >
                <Grid item xs={6} zeroMinWidth>
                  <Typography variant="subtitle2" noWrap>
                    {translate(`resources.order.fields.error_source`)}
                  </Typography>
                </Grid>
                <Grid item xs={6} zeroMinWidth>
                  {["FedEx", "UPS"].indexOf(orderRecord.error_source) === -1 ? (
                    <Typography variant="body2" align="right">
                      {orderRecord.error_source}
                    </Typography>
                  ) : (
                    <CargoAvatarField
                      ship_via={orderRecord.ship_via}
                      className={classes.avatarFlex}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                className={classes.featuresGrid}
                container
                wrap="wrap"
                key="shipment-row-error_message"
              >
                <Grid item xs={6} zeroMinWidth>
                  <Typography variant="subtitle2">
                    {translate(`resources.order.fields.error_message`)}
                  </Typography>
                </Grid>
                <Grid item xs={6} zeroMinWidth>
                  <Typography variant="body2" align="right">
                    {orderRecord.error_message}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ) : null}
          <ShipmentAccordion
            key={`ShipmentAccordion-component${orderRecord.id}`}
            orderRecord={orderRecord}
            fetching={autoRenewAction.loading}
            renewShipment={autoRenewShipment}
            correctShipment={autoCorrectShipment}
            cancelShipment={autoCancelShipment}
            moveShipment={moveShipmentCancelled}
            deleteShipment={deleteShipment}
          />
          <ItemsAccordion
            key={`ItemsAccordion-component${orderRecord.id}`}
            orderRecord={orderRecord}
            fetching={autoRenewAction.loading}
          />
          <CustomerAccordion
            key={`CustomerAccordion-component${orderRecord.id}`}
            orderRecord={orderRecord}
            fetching={autoRenewAction.loading}
          />
        </div>
      </div>
    </div>
  ) : (
    <LinearProgress color="secondary" />
  );
};

export default OrderShow;
