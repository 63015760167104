import React from "react";
import { useTranslate } from "react-admin";
import { Identifier } from "ra-core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Chip,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AllOutIcon from "@material-ui/icons/AllOut";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";

import { makeStyles } from "@material-ui/core/styles";
import { deepPurple, yellow, grey } from "@material-ui/core/colors";

import { PackageItem, Order, Int } from "../types";
import CargoTrackingNumberField from "../orders/CargoTrackingNumberField";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  accordionSummary: {
    backgroundColor: deepPurple[50],
    "&.Mui-expanded": {
      backgroundColor: deepPurple[100],
    },
  },
  accordionDetails: {
    fontSize: "0.875rem",
    flexDirection: "column",
    overflow: "hidden",
    width: "auto",
    padding: "12px 16px",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  purpleInvert: {
    color: deepPurple[500],
    backgroundColor: "transparent",
  },
  paper: {
    margin: "-8px -12px",
    width: "calc(100% + 24px)",
    backgroundColor: "trasnparent",
  },
  featuresGrid: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& *": {
      letterSpacing: "0.01071em",
      // pointerEvents: "none",
    },
    padding: "6px 8px",
    margin: "2px auto",
  },
  chipper: {
    flex: "0 0 auto",
    marginLeft: "auto",
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    borderRadius: "0",
  },
  totalHolder: {
    flex: "0 1 auto",
    marginLeft: "auto",
    padding: "0 0.5rem",
    backgroundColor: grey[200],
    borderColor: grey[800],
    borderWidth: "1px",
    borderStyle: "solid",
  },
  counterHolder: {
    flex: "0 1 auto",
    marginLeft: "auto",
    padding: "0 0.5rem",
    backgroundColor: yellow[100],
    borderColor: yellow[800],
    borderWidth: "1px",
    borderStyle: "solid",
  },
  anyDivider: {
    margin: theme.spacing(1, 0),
  },
}));

interface Props {
  orderRecord: Order;
  fetching: boolean;
}

const ItemsAccordion: React.FC<Props> = ({
  orderRecord,
  fetching = false,
  ...others
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [itemquantity, setItemQuantity] = React.useState(
    orderRecord.item_quantity
  );
  const [totalquantity, setTotalQuantity] = React.useState(
    orderRecord.total_quantity
  );
  const [packages, setPackages] = React.useState<PackageItem[]>(
    orderRecord.packages
  );
  const [svipVia, setShipVia] = React.useState<string | null>(
    orderRecord.ship_via
  );

  let trackPackage: Identifier[] = [];

  React.useEffect(() => {
    setItemQuantity(
      (orderRecord.item_quantity ? orderRecord.item_quantity : 0) as Int
    );
    setTotalQuantity(
      (orderRecord.total_quantity ? orderRecord.total_quantity : 0) as Int
    );
    setShipVia(orderRecord.ship_via);
    setPackages((p) =>
      orderRecord.packages !== undefined ? orderRecord.packages : p
    );
  }, [orderRecord]);

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      square={true}
      key={`items-accordion-root-${orderRecord.id}`}
      defaultExpanded={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="items-content"
        id="items-header"
        className={classes.accordionSummary}
        key={`items-accordion-root-summary-${orderRecord.id}`}
      >
        <Avatar
          className={`${classes.purpleInvert} ${classes.small}`}
          key={`items-accordion-root-summary-avatar-${orderRecord.id}`}
        >
          <AllOutIcon />
        </Avatar>
        <Typography
          className={classes.heading}
          key={`items-accordion-root-summary-heading-${orderRecord.id}`}
        >
          Items
        </Typography>
        <Chip
          icon={<ArrowRightIcon fontSize="small" />}
          size="small"
          className={classes.chipper}
          label={`${itemquantity} / ${totalquantity}`}
          variant="outlined"
          key={`items-accordion-root-summary-chip-${orderRecord.id}`}
        />
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetails}
        aria-expanded={true}
        key={`items-accordion-root-details-${orderRecord.id}`}
      >
        {fetching ? (
          <LinearProgress color="secondary" />
        ) : (
          <Paper
            className={classes.paper}
            key={`items-accordion-root-paper-${orderRecord.id}`}
          >
            <Grid
              className={classes.featuresGrid}
              container
              wrap="wrap"
              key={`item-quantitiy-total-number-grid-${orderRecord.id}`}
            >
              <Grid
                item
                xs={6}
                zeroMinWidth
                key={`item-quantitiy-key-grid-${orderRecord.id}`}
              >
                <Typography variant="subtitle2" color="textPrimary" noWrap>
                  {translate(`resources.order.fields.item_quantity`)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                zeroMinWidth
                className={classes.totalHolder}
                key={`item-quantitiy-value-grid-${orderRecord.id}`}
              >
                <Typography
                  variant="caption"
                  key={`item-quantitiy-value-${orderRecord.id}`}
                >
                  {itemquantity}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.featuresGrid}
              container
              wrap="wrap"
              key={`total-quantitiy-grid-${orderRecord.id}`}
            >
              <Grid
                item
                xs={6}
                zeroMinWidth
                key={`total-quantitiy-key-grid-${orderRecord.id}`}
              >
                <Typography variant="subtitle2" color="textPrimary" noWrap>
                  {translate(`resources.order.fields.total_quantity`)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                zeroMinWidth
                className={classes.totalHolder}
                key={`total-quantitiy-value-grid-${orderRecord.id}`}
              >
                <Typography
                  variant="caption"
                  key={`total-quantitiy-value-${orderRecord.id}`}
                >
                  {totalquantity}
                </Typography>
              </Grid>
            </Grid>
            {Array.isArray(packages) ? (
              packages.map((itemObj: PackageItem, idx) => {
                if (trackPackage.indexOf(itemObj.item_id) >= 0) return null;
                else trackPackage.push(itemObj.item_id);

                let groups = packages.filter(
                  (o) => o.item_id === itemObj.item_id
                );

                let location = "";

                return (
                  <div key={`unique-item-${itemObj.item_id}-${idx}`}>
                    <Grid
                      className={classes.featuresGrid}
                      container
                      wrap="wrap"
                      alignItems="center"
                      key={`unique-item-primary-${itemObj.item_id}-${idx}`}
                    >
                      <Grid
                        item
                        xs={2}
                        zeroMinWidth
                        key={`unique-item-icon-${orderRecord.id}-${idx}`}
                      >
                        <Avatar variant="square">
                          <HorizontalSplitIcon />
                        </Avatar>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        zeroMinWidth
                        key={`unique-item-value-${orderRecord.id}-${idx}`}
                      >
                        <Typography variant="h6" color="textPrimary">
                          {itemObj.item_id}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        zeroMinWidth
                        className={classes.counterHolder}
                        key={`unique-item-counter-${orderRecord.id}-${idx}`}
                      >
                        <Typography variant="h4">{groups.length}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.featuresGrid}
                      container
                      wrap="wrap"
                      alignItems="center"
                      key={`unique-item-trackingnumber-${itemObj.item_id}-${idx}`}
                    >
                      <Grid
                        item
                        xs={6}
                        zeroMinWidth
                        key={`unique-item-trackingnumber-key-${orderRecord.id}-${idx}`}
                      >
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          noWrap
                        >
                          {translate(
                            `resources.order.fields.packages.tracking_number`
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} zeroMinWidth>
                        <List
                          dense={true}
                          key={`unique-item-trackingnumber-list-${orderRecord.id}-${idx}`}
                        >
                          {groups.map((trackingObj: PackageItem, idxy) => {
                            return (
                              <ListItem
                                key={`unique-item-trackingnumber-listitem-${orderRecord.id}-${idx}-${idxy}`}
                              >
                                <ListItemText
                                  secondary={
                                    <CargoTrackingNumberField
                                      ship_via={svipVia}
                                      tracking_number={
                                        trackingObj.tracking_number
                                      }
                                      key={`items-trackingnumber-listitem-value-field-${orderRecord.id}-${idx}-${idxy}`}
                                    />
                                  }
                                  key={`unique-item-trackingnumber-listitem-text-${orderRecord.id}-${idx}-${idxy}`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.featuresGrid}
                      container
                      wrap="wrap"
                      alignItems="center"
                      key={`unique-item-location-${itemObj.item_id}-${idx}`}
                    >
                      <Grid
                        item
                        xs={6}
                        zeroMinWidth
                        key={`unique-item-location-key-${orderRecord.id}-${idx}`}
                      >
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          noWrap
                        >
                          {translate(
                            `resources.order.fields.packages.location`
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} zeroMinWidth>
                        <List
                          dense={true}
                          key={`unique-item-location-list-${orderRecord.id}-${idx}`}
                        >
                          {groups.map((locationObj: PackageItem, idxy) => {
                            if (locationObj.location === location) return null;
                            else {
                              location = String(locationObj.location);
                              return (
                                <ListItem
                                  key={`unique-item-location-listitem-${orderRecord.id}-${idx}-${idxy}`}
                                >
                                  <ListItemText
                                    secondary={
                                      <React.Fragment>
                                        <span>{`${
                                          groups.filter(
                                            (g) =>
                                              g.location ===
                                              locationObj.location
                                          ).length
                                        } @ `}</span>
                                        <strong>{`${locationObj.location}`}</strong>
                                      </React.Fragment>
                                    }
                                    key={`unique-item-location-listitem-text-${orderRecord.id}-${idx}-${idxy}`}
                                  />
                                </ListItem>
                              );
                            }
                          })}
                        </List>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.featuresGrid}
                      container
                      wrap="wrap"
                      alignItems="center"
                      key={`unique-item-dims-${itemObj.item_id}-${idx}`}
                    >
                      <Grid item xs={6} zeroMinWidth>
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          noWrap
                          key={`items-dims-key-grid-${orderRecord.id}-${idx}`}
                        >
                          {translate(`resources.order.fields.packages.dims`)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        zeroMinWidth
                        key={`items-dims-value-grid-${orderRecord.id}-${idx}`}
                      >
                        <Typography
                          variant="caption"
                          align="right"
                          color="textSecondary"
                          noWrap
                        >
                          {`${Math.round(itemObj.length) as Int}" x ${
                            Math.round(itemObj.width) as Int
                          }" x ${Math.round(itemObj.height) as Int}"`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.featuresGrid}
                      container
                      wrap="wrap"
                      alignItems="center"
                      key={`unique-item-weight-${itemObj.item_id}-${idx}`}
                    >
                      <Grid
                        item
                        xs={6}
                        zeroMinWidth
                        key={`items-weight-key-grid-${orderRecord.id}-${idx}`}
                      >
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          noWrap
                        >
                          {translate(`resources.order.fields.packages.weight`)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        zeroMinWidth
                        key={`items-weight-value-grid-${orderRecord.id}-${idx}`}
                      >
                        <Typography
                          variant="caption"
                          align="right"
                          color="textSecondary"
                          noWrap
                        >
                          {`${itemObj.weight} (lbs)`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            ) : (
              <Grid
                className={classes.featuresGrid}
                container
                wrap="wrap"
                key={`items-features-nopackage-grid-${orderRecord.id}`}
              >
                <Grid
                  item
                  xs={12}
                  zeroMinWidth
                  key={`items-features-nopackage-key-grid-${orderRecord.id}`}
                >
                  <Typography
                    variant="subtitle2"
                    align="center"
                    noWrap
                    className={classes.counterHolder}
                  >
                    {translate(`resources.order.fields.packages.empty`)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Paper>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ItemsAccordion;
