import React, { FC } from "react";
import { FieldProps, Order } from "../types";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import CargoAvatarField from "./CargoAvatarField";

interface Props extends FieldProps<Order> {
  className?: string;
}

const ShippingCargoField: FC<Props> = ({ record, className }) => {
  return record && record.ship_via ? (
    <CargoAvatarField ship_via={record.ship_via} className={className} />
  ) : (
    <NotListedLocationIcon fontSize="small" className={className} />
  );
};

ShippingCargoField.defaultProps = {
  label: "resources.order.fields.ship_via",
  source: "ship_via",
  addLabel: true,
};

export default ShippingCargoField;
