import React, { FC, Fragment } from "react";
import classnames from "classnames";
import {
  ExportButton,
  Datagrid,
  List,
  Pagination,
  RefreshButton,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { fetchUtils } from "ra-core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { BulkActionProps, ListComponentProps } from "../types";
import { PaginationProps } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";

import AutorenewIcon from "@material-ui/icons/Autorenew";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flex: "1 0 auto",
    minHeight: "100%",
  },
  list: {
    flexGrow: 1,
    paddingRight: "0.5em",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  headerRow: {
    borderLeftColor: "white",
    borderLeftWidth: 0,
    borderLeftStyle: "solid",
    borderBottomColor: grey[500],
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  headerCell: {
    padding: "8px",
    backgroundColor: grey[200],
  },
  rowCell: {
    padding: "8px",
    verticalAlign: "middle",
  },
}));

const raThemeProvider = {
  overrides: {
    RaListToolbar: {
      toolbar: {
        backgroundColor: "rgb(255, 255, 255)",
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        color: "#2b1a1a",
        height: "64px",
        minHeight: "64px",
        backgroundColor: "rgb(133, 176, 198)",
      },
    },
  },
};

const httpClient = fetchUtils.fetchJson;

const BoxListIn: FC<ListComponentProps<{ id: string }>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { addToast } = useToasts();

  const [autoRenewAction, setAutorenewAction] = React.useState({
    loading: false,
    disabled: false,
  });

  const handleBoxAutorenewAction = () => {
    if (!autoRenewAction.loading) {
      setAutorenewAction({ loading: true, disabled: true });
      addToast(translate("resources.box.actions.refresh.process"), {
        appearance: "success",
      });
      let options = {
        headers: new Headers({}),
      };
      const email = localStorage.getItem("email");
      options.headers.set("X-Email", email ? email : "");
      httpClient(`${process.env.REACT_APP_HEROKU_API_URL}/box/sync`, options)
        .then((response) => {
          switch (response.json.status) {
            case "success":
              addToast(translate("resources.box.actions.refresh.success"), {
                appearance: "success",
              });
              break;
            case "error":
              addToast(
                translate("resources.box.actions.refresh.error", {
                  reason: response.json.message,
                }),
                {
                  appearance: "error",
                }
              );
              break;
            default:
              addToast(translate("resources.box.actions.refresh.unknown"), {
                appearance: "warning",
              });
              break;
          }
        })
        .catch((reason) => {
          addToast(
            translate("resources.box.actions.refresh.error", {
              reason: reason,
            }),
            {
              appearance: "error",
            }
          );
        })
        .finally(() => {
          setAutorenewAction({ loading: false, disabled: false });
        });
    }
  };

  const BoxBulkActionButtons: FC<BulkActionProps> = (props) => (
    <Fragment></Fragment>
  );

  const BoxListActions: FC<any> = () => (
    <TopToolbar>
      {/* <SortButton fields={["length", "width", "height"]} /> */}
      <RefreshButton
        label="Import Boxes"
        icon={<AutorenewIcon />}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          handleBoxAutorenewAction();
        }}
      />
      <ExportButton />
    </TopToolbar>
  );

  const PostPagination = (props: PaginationProps) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );

  return (
    <div className={classes.root}>
      {autoRenewAction.loading ? (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <List
          {...props}
          className={classnames(classes.list)}
          bulkActionButtons={<BoxBulkActionButtons />}
          sort={{ field: "id", order: "ASC" }}
          pagination={<PostPagination />}
          perPage={50}
          actions={<BoxListActions />}
        >
          <Datagrid
            classes={{
              headerRow: classes.headerRow,
              headerCell: classes.headerCell,
              rowCell: classes.rowCell,
            }}
            optimized
          >
            <TextField source="id" />
            <TextField source="length" />
            <TextField source="width" />
            <TextField source="height" />
          </Datagrid>
        </List>
      )}
    </div>
  );
};

const BoxList: FC<ListComponentProps<{ id: string }>> = (props) => {
  return (
    <ToastProvider autoDismiss placement="bottom-center">
      <ThemeProvider theme={raThemeProvider}>
        <BoxListIn {...props} />
      </ThemeProvider>
    </ToastProvider>
  );
};

export default BoxList;
